import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap-v5";
import { useGetConciliationById } from "../hooks/ConsilationHook";
import { ConcilationPdf } from "../components/ConcilationPdf";

type Props = {
  show: boolean;
  handleClose: (updateGrid: boolean) => void;
  concilation: number;
  strDate: string;
};

const PdfPreviewConciliationModal: React.FC<Props> = ({
  show,
  handleClose,
  concilation,
  strDate,
}) => {
  const { conciliationDetail, loadingRequest } =
    useGetConciliationById(concilation);
  const [detail, setDetail] = useState<any>([]);

  useEffect(() => {
    if (conciliationDetail && concilation > 0) {
      setDetail(conciliationDetail);
    } else {
      setDetail([]);
    }
  }, [conciliationDetail]);

  useEffect(() => {
    if (!show) {
      setDetail([]);
    }
  }, [show]);

  return (
    <Modal
      id="kt_modal_create"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-850px"
      show={show}
      onHide={() => {
        handleClose(false);
      }}
    >
      <div className="modal-content">
        <ConcilationPdf
          strDate={strDate}
          data={detail}
          handleClose={handleClose}
        ></ConcilationPdf>
      </div>
    </Modal>
  );
};
export { PdfPreviewConciliationModal };
