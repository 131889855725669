import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { StartShiftModel } from "../models/StartShiftModel";
import {
  getAllShiftSupplier,
  getAllSuppliersClosed,
  startDaySupplier,
} from "../../../service/start_shift/StartShift";
import { SimpleAlert } from "../../../../rbts/helpers";
import { SelectFormat } from "../../../../rbts/helpers/models/GenericModel";
import { getAllSuppliersTypeJob } from "../../../service/sales/Sales";

export const useGetAllShiftSupplier = (reload: number) => {
  const [shiftList, setShiftList] = useState<StartShiftModel[]>([]);
  const [loadingRequest, setLoadingRequest] = useState<boolean>(true);
  const history = useHistory();

  useEffect(() => {
    const fetchPost = async () => {
      const res = await getAllShiftSupplier()
        .then()
        .catch((error) => {
          history.push("/error/500");
        });
      if (res) {
        setShiftList(res.rows);
        setLoadingRequest(false);
      }
    };
    fetchPost();
  }, [reload]);

  return { shiftList, loadingRequest };
};

export const useExecuteStarShiftSuplier = () => {
  const [loadingRequest, setLoadingRequest] = useState<boolean>(false);

  const startDay = async (supplierId: number) => {
    setLoadingRequest(true);
    try {
      await startDaySupplier(supplierId);
      setLoadingRequest(false);
      SimpleAlert("Se ha iniciado el turno correctamente", 3000, "success");
    } catch (err: any) {
      setLoadingRequest(false);
      let resMessageToast: string = "";
      if (err.response && err.response.data && err.response.data.message) {
        resMessageToast = err.response.data.message;
      } else {
        resMessageToast =
          "Hubo un problema para iniciar turno, intentalo nuevamente.";
      }
      SimpleAlert(resMessageToast, 3000, "error");
      throw resMessageToast;
    }
  };

  return { startDay, loadingRequest };
};

export const useSelectSuppliersClosed = () => {
  const [suppliers, setSuppliers] = useState<SelectFormat[]>([]);
  const [loadingRequest, setLoadingRequest] = useState<boolean>(true);
  const history = useHistory();
  let arr: any = [];

  useEffect(() => {
    const fetchPost = async () => {
      const res = await getAllSuppliersClosed()
        .then()
        .catch((error) => {
          history.push("/error/500");
        });
      if (res) {
        setSuppliers(res);
        setLoadingRequest(false);
      }
    };

    fetchPost();
  }, []);

  return { suppliers, loadingRequest };
};
