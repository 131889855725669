import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { toAbsoluteUrl } from '../../../rbts/helpers';

export function TerminosPage() {
  useEffect(() => {
    document.body.classList.add('bg-white');
    return () => {
      document.body.classList.remove('bg-white');
    };
  }, []);

  return (
    <>
      <div className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed">
        <div className="d-flex flex-center flex-column ">
          <img
            alt="Logo"
            src={toAbsoluteUrl('/media/logos/logo_2.png')}
            className="h-150px mt-10"
          />
          <div id="terminos" className="container mt-15 mb-10">
            <h1>
              Términos y Condiciones del TicketCash para el evento Kermes
              Parroquia Fátima 2024
            </h1>
            <p>
              Los siguientes términos y condiciones rigen el uso del TicketCash
              proporcionado para el evento específico mencionado. Al utilizar el
              TicketCash, aceptas cumplir con estos términos y condiciones en su
              totalidad. Por favor, lee detenidamente antes de utilizar el
              TicketCash.
            </p>

            <h2>1 - Definiciones</h2>
            <p>
              <strong>Evento:</strong> Se refiere al evento específico para el
              cual se ha emitido el TicketCash.
            </p>
            <p>
              <strong>TicketCash:</strong> Es un sistema virtual de pago que
              permite a los usuarios realizar transacciones dentro del evento.
            </p>
            <p>
              <strong>Usuario:</strong> Cualquier persona que utilice el
              TicketCash durante el evento.
            </p>

            <h2>2 - Uso del TicketCash</h2>
            <p>
              El TicketCash es exclusivamente para uso dentro del evento
              mencionado y está destinado únicamente para transacciones
              relacionadas con el evento.
            </p>
            <p>
              El TicketCash es intransferible y solo puede ser utilizado por el
              titular registrado.
            </p>
            <p>
              No se permite la retirada de efectivo del TicketCash en ningún
              momento.
            </p>
            <p>El saldo del TicketCash no devengará intereses.</p>

            <h2>3 - Carga y Recargas</h2>
            <p>
              El TicketCash puede ser cargado previamente antes del evento o
              durante el mismo, según lo permita la organización del evento.
            </p>
            <p>
              La cantidad mínima y máxima permitida para cargar o recargar el
              TicketCash puede variar y será determinada por la organización del
              evento.
            </p>
            <p>
              Las recargas del TicketCash son no reembolsables y no pueden ser
              transferidas a otras personas ni a otros eventos y se debe
              consumir durante la vigencia del evento.
            </p>

            <h2>4 - Transacciones y Saldo</h2>
            <p>
              El TicketCash solo puede ser utilizado para realizar compras
              dentro del evento en los puntos de venta designados.
            </p>
            <p>
              Al realizar una transacción, el saldo disponible en el TicketCash
              se verá reducido en la cantidad correspondiente a la compra.
            </p>
            <p>
              Es responsabilidad del usuario asegurarse de tener suficiente
              saldo en el TicketCash antes de realizar una transacción.
            </p>
            <p>
              Se puede verificar el saldo del TicketCash en los puntos de
              información designados o a través de la aplicación móvil oficial
              del evento, si está disponible.
            </p>

            <h2>5 - Pérdida o Robo</h2>
            <p>
              En caso de pérdida o robo del TicketCash, el usuario debe informar
              inmediatamente a la autoridad designada del evento o personal de
              staff.
            </p>
            <p>
              La organización del evento no se hace responsable de la pérdida,
              robo o uso no autorizado del TicketCash.
            </p>
            <p>
              Se requiere la identificación del usuario para emitir un reemplazo
              del TicketCash, con el saldo que tenga disponible en ese momento.
            </p>

            <h2>6 - Terminación del Servicio</h2>
            <p>
              La organización del evento se reserva el derecho de terminar el
              servicio del TicketCash en cualquier momento y por cualquier
              motivo sin previo aviso.
            </p>
            <p>
              En caso de terminación del servicio, el usuario tendrá derecho a
              reclamar el saldo restante del TicketCash, sujeto a los términos y
              condiciones establecidos por la organización del evento.
            </p>
            <p>
              El TicketCash solo será canjeado por otro TicketCash en caso de
              robo o extravío únicamente en el caso de que el usuario haya
              enrolado su TicketCash en los centros de atención con su nombre e
              identificación. Aplican consideraciones de la situación de robo o
              extravío para este proceso.
            </p>

            <h2>7 - Modificaciones de los Términos y Condiciones</h2>
            <p>
              La organización del evento se reserva el derecho de realizar
              cambios en estos términos y condiciones en cualquier momento sin
              previo aviso.
            </p>
            <p>
              Los cambios entrarán en vigor inmediatamente después de su
              publicación en el sitio web oficial del evento o en otros medios
              de comunicación proporcionados por la organización.
            </p>

            <p>
              Al utilizar el TicketCash, aceptas automáticamente estos términos
              y condiciones. Si no estás de acuerdo con estos términos y
              condiciones, no deberá utilizar el TicketCash.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
