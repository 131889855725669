import { FC } from "react";
import { PageTitle, PageLink } from "../../../rbts/layout/core";
import { View } from "./components/View";

const ConciliationPage: FC = () => {
  const widgetsBreadCrumbs: Array<PageLink> = [];

  return (
    <>
      <PageTitle
        breadcrumbs={widgetsBreadCrumbs}
      >{`${"Conciliación"}`}</PageTitle>
      <View />
    </>
  );
};

export { ConciliationPage };
