import React, { useState, useEffect } from "react";
import Select from "react-select";
import { styleSelect } from "../../../../../rbts/assets/ts/_utils";

import {
  LoadingGrid,
  SimpleAlert,
  TableList,
  formatDateString,
  formatPrice,
} from "../../../../../rbts/helpers";
import { useGetAllDaysSelect } from "../hooks";

type Props = {};

const CourtesiesDetail: React.FC<Props> = ({}) => {
  const { catalog: day } = useGetAllDaysSelect();
  const [strDate, setStrDate] = useState<string>("");
  const [currentDay, setCurrentDay] = useState<number>(0);
  const detalleInicial: any = [];
  const loadingRequest = false;

  const columns: any = [
    {
      id: "folio",
      name: <div className="fw-bolder text-muted me-5">Nombre</div>,
      selector: (row: any) => row.folio,
      cell: (row: any) => (
        <>
          <span className="text-primary">
            {row.folio ? row.folio : "No registrado"}
          </span>
        </>
      ),
      sortable: false,
      width: "25%",
    },
    {
      id: "punto_venta",
      name: <div className="fw-bolder text-muted me-5">Ticketcash</div>,
      cell: (row: any) => (
        <>
          <span>
            {row.punto_venta ? row.punto_venta?.nombre : "No registrado"}
          </span>
        </>
      ),
      sortable: false,
      width: "20%",
    },
    {
      id: "proveedor",
      name: <div className="fw-bolder text-muted me-5">Saldo electrónico</div>,
      cell: (row: any) => (
        <>
          <span>{"No registrado"}</span>
        </>
      ),
      sortable: false,
      width: "60%",
    },
  ];
  return (
    <>
      <div className="row gy-5 g-xl-8 w-100">
        <div className="col-xl-12">
          {loadingRequest ? (
            <LoadingGrid text="Cargando..."></LoadingGrid>
          ) : (
            <TableList
              data={[]}
              columns={columns}
              minHeight="200px"
            ></TableList>
          )}
        </div>
      </div>
    </>
  );
};

export { CourtesiesDetail };
