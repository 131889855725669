
export const styleSelect = {
    placeholder: (provided: any, state: any) => ({
        ...provided,
        color: '#181c32',
    }),
    indicatorSeparator: (provided: any, state: any) => ({
        ...provided,
        display: 'none',
    }),
    dropdownIndicator: (provided: any, state: any) => ({
        ...provided,
        color: '#9096EC',
    }),
    control: (provided: any, state: any) => ({
        ...provided,
        border: '1px solid #e4e6ef',
        borderRadius: '6px',
        padding: '3px',
        fontSize: '1rem',
        boxShadow: 'none',
        backgroundColor: state.isDisabled ? '#EFF2F5' : 'white',
    }),
    singleValue: (provided: any, state: any) => ({
        ...provided,
        color: state.isDisabled ? '#181c32' : 'inherit',
        fontWeight: 500,
    }),
    option: (provided: any, state: any) => ({
        ...provided,
        // fontSize: state.data.value === '0' ? '1.1rem' : '1.0rem',
        color: state.data.value === '0' ? '#9096EC' : '#000',
    }),
    menubar: (provided: any, state: any) => ({
        ...provided,
        zIndex: 999
    })
};