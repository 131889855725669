import React, { useState, useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { MenuComponent } from "../../../../../rbts/assets/ts/components";
import {
  KTSVG,
  toAbsoluteUrl,
  LoadingGrid,
  TableList,
} from "../../../../../rbts/helpers";
import { UserModel } from "../../../../modules/auth/models/UserModel";
// import { useGetAllDonationsList } from "../hooks/DonationsHook";
// import { RootState } from "../../../../setup";
import { RootState } from "../../../../../setup";
import { SupplierModel } from "../models/SupplierAgreementsModel";
import { dateFormatStringText } from "../../../../../rbts/helpers/DateFormat";
import { SupplierForm } from "./SupplierForm";

type Props = {};

let initUser: SupplierModel = {
  id: 0,
  alias: "",
  first_name: "",
  father_last_name: "",
  mother_last_name: "",
  email: "",
  phone: "",
  profile_photo: "",
  company: "",
  active: false,
};

const View: React.FC<Props> = () => {
  const [loadingView, setloadingView] = useState(true);
  const [reloadGrid, setReloadGrid] = useState(Math.random() * 40);
  const [initFilterValues] = useState({});
  //   const { listDonations, loadingRequest } = useGetAllDonationsList(reloadGrid);
  const history = useHistory();

  const [userSelected, setUserSelected] = useState<UserModel>(initUser);
  const [userTitleModal, setUserTitleModal] = useState("");
  const [activeTitleModal, setActiveTitleModal] = useState("");
  const [activeDescriptionModal, setActiveDescriptionModal] = useState("");
  const [visivilityModal, setVisivilityModal] = useState(false);
  const [visivilityActiveModal, setVisivilityActiveModal] = useState(false);
  const [visivilityRolesModal, setVisivilityRolesModal] = useState(false);
  const [userCurrentId, setUserCurrentId] = useState(0);

  const userCurrent: UserModel = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  ) as UserModel;

  const activeUser = (id: number, active: boolean, user: any) => {
    if (active) {
      setActiveTitleModal("Desactivar");
      setActiveDescriptionModal(
        "Si confirma está acción el usuario no podrá acceder al sistema."
      );
    } else {
      setActiveTitleModal("Activar");
      setActiveDescriptionModal(
        "Si confirma está acción el usuario podrá acceder al sistema."
      );
    }
    setUserSelected(user);
    setVisivilityActiveModal(!visivilityActiveModal);
  };
  const updateUser = (id: number, user: any) => {
    setReloadGrid(Math.random() * 40);
    setUserSelected(user);
    setUserTitleModal("Editar usuario");
    setVisivilityModal(!visivilityModal);
  };
  const updateRoles = (id: number, user: any, userCurrentId: number) => {
    setReloadGrid(Math.random() * 40);
    setUserSelected(user);
    setVisivilityRolesModal(!visivilityRolesModal);
    setUserCurrentId(userCurrentId);
  };
  const addUser = () => {
    setReloadGrid(Math.random() * 40);
    setUserSelected(initUser);
    setUserTitleModal("Agregar usuario");
    setVisivilityModal(!visivilityModal);
  };

  //   useEffect(() => {
  //     if (loadingRequest > 0) {
  //       setloadingView(false);
  //       setTimeout(() => {
  //         MenuComponent.reinitialization();
  //       }, 500);
  //     }
  //   }, [loadingRequest]);

  return (
    <>
      <div className="row gy-5 g-xl-8">
        <div className="col-xl-12">
          <div className={`card card-xxl-stretch mb-5 mb-xl-12`}>
            <div className="card-header pt-5">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bold text-gray-800">
                  Proveedor
                </span>
              </h3>
              <h3 className="card-title">
                <span className="card-label fw-bold text-gray-800">
                  Fecha administrativa:
                </span>
                <span className="fs-4 text-gray-700">
                  {dateFormatStringText(new Date())}
                </span>
              </h3>

              <div className="row w-100 ">
                <div className="col-md-12 ">
                  <SupplierForm id={"0"}></SupplierForm>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { View };
