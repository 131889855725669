import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import { NumericFormat } from "react-number-format";
import { KTSVG, SimpleAlert, formatPrice } from "../../../../../rbts/helpers";
import { styleSelect } from "../../../../../rbts/assets/ts/_utils";
import { useSelectProducts } from "../hooks/SalesSupplierHook";
import CountUp from "react-countup";

type Props = {
  data: any[];
  onGetDataProducts: (products: any) => void;
};

const ProductsList: React.FC<Props> = ({ data, onGetDataProducts }) => {
  const { products, loadingRequest } = useSelectProducts();
  const [totalAmount, setTotalAmount] = useState<number>(0);
  const [totalPriceProducts, setTotalPriceProducts] = useState<number>(0);
  const [dataMemo, setDataMemo] = useState<any[]>(data);
  const inputAmountRefs = useRef<any>([]);
  const inpuntPriceRefs = useRef<any>([]);

  useEffect(() => {
    if (dataMemo.length) {
      const totalAmount = dataMemo.reduce(
        (acumulador, elemento) => acumulador + Number(elemento.cantidad),
        0
      );
      const totalPriceProducts = dataMemo.reduce(
        (acumulador, elemento) => acumulador + Number(elemento.total),
        0
      );

      setTotalAmount(totalAmount);
      setTotalPriceProducts(totalPriceProducts);
    }
  }, [dataMemo]);

  const productsSelected = (updatedDataMemo: any) => {
    onGetDataProducts(updatedDataMemo);
  };

  const AddRow = () => {
    const cleanedData = dataMemo.filter((item) => item.id !== 0);
    const newRow: any = {
      id: 0,
      nombre: "Nuevo producto",
      cantidad: 1,
      precio: 0,
      total: 0,
    };
    setDataMemo([...cleanedData, newRow]);
  };

  const addProduct = (selectedOption: any) => {
    if (selectedOption) {
      const {
        data: { id, nombre },
      } = selectedOption;
      const cleanedData = dataMemo.filter((item) => item.id !== 0);
      const existingIndex = dataMemo.findIndex((item) => item.id === id);
      if (existingIndex === -1) {
        const updateRow = {
          id: id,
          nombre: nombre,
          precio: 0,
          cantidad: 1,
          total: 0,
        };
        setDataMemo((prevDataMemo) => {
          const updatedDataMemo = [...prevDataMemo, updateRow];
          return updatedDataMemo;
        });
        setDataMemo([...cleanedData, updateRow]);
      } else {
        setDataMemo([...cleanedData]);
        SimpleAlert("Ya existe el producto en la venta actual!", 3000, "error");
      }
    }
  };

  const deleteRowById = (productId: number) => {
    setDataMemo((prevDataMemo) => {
      const updatedDataMemo = prevDataMemo.filter(
        (item) => item.id !== productId
      );
      productsSelected(updatedDataMemo);
      return updatedDataMemo;
    });
  };

  useEffect(() => {
    const cleanedData = data.filter((item) => item.id !== 0);
    setDataMemo(cleanedData);
  }, [data]);

  const handlePriceOnChange = async (value: any, id: any) => {
    const priceSale = value ? parseFloat(value) : 0;

    const updatedItems = dataMemo.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          precio: priceSale ?? 0,
          total: item.cantidad * priceSale ?? 0,
        };
      }
      return item;
    });
    productsSelected(updatedItems);
    setDataMemo(updatedItems);
  };

  const handleAmountChange = (e: any, id: any) => {
    const amount =
      e.target.value.trim() === "" || e.target.value == 0 ? 1 : e.target.value;

    if (amount > 0) {
      if (amount === "" || /^\d*\.?\d{0,1}$/.test(amount)) {
        const updatedItems = dataMemo.map((item) => {
          if (item.id === id) {
            return {
              ...item,
              cantidad: amount,
              total: amount * item.precio ?? 0,
            };
          }
          return item;
        });
        productsSelected(updatedItems);
        setDataMemo(updatedItems);
      }
    } else {
      const updatedItems = dataMemo.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            cantidad: 1,
            total: 1 * item.precio,
          };
        }
        return item;
      });
      productsSelected(updatedItems);
      setDataMemo(updatedItems);
    }
  };

  return (
    <>
      <div className="table-responsive min-h-300px me-n5 pe-5 scroll-y w-100 mt-5">
        <table className="table table-border table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 me-n5 pe-5 w-100">
          <thead>
            <tr className="text-gray-800 fs-7">
              <th className="w-300px">Nombre</th>
              <th className="w-250px">Precio</th>
              <th className="w-250px">Cantidad</th>
              <th className="w-250px">Total</th>
              <th className="w-100px"></th>
            </tr>
          </thead>
          <tbody className="">
            {dataMemo.length > 0 ? (
              dataMemo.map((row, index) => (
                <>
                  <tr key={row.id}>
                    <td>
                      <div className="d-flex align-items-center">
                        <div className="d-flex justify-content-start w-100 flex-column fs-012 font-weight-mormal text-primary">
                          {row.id === 0 ? (
                            <Select
                              key={row.id}
                              options={products}
                              styles={styleSelect}
                              placeholder={"Seleccione"}
                              isSearchable={true}
                              menuPortalTarget={document.body}
                              menuPosition={"fixed"}
                              noOptionsMessage={() => {
                                return (
                                  <span>{"No se encontraron registros"}</span>
                                );
                              }}
                              onChange={addProduct}
                              isLoading={loadingRequest}
                              backspaceRemovesValue
                            />
                          ) : (
                            <>{row.nombre}</>
                          )}
                        </div>
                      </div>
                    </td>
                    {row.id ? (
                      <>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column fs-012 text-gray-600">
                              <NumericFormat
                                placeholder="$0.00"
                                defaultValue={`${formatPrice(
                                  row.precio,
                                  "es-MX",
                                  "MXN",
                                  2,
                                  2
                                )}`}
                                getInputRef={(el: any) =>
                                  (inpuntPriceRefs.current[index] = el)
                                }
                                className="form-control border-0"
                                thousandSeparator={true}
                                prefix="$"
                                decimalScale={2}
                                fixedDecimalScale={true}
                                allowNegative={false}
                                allowLeadingZeros={false}
                                onValueChange={(values: any) => {
                                  const { floatValue } = values;
                                  handlePriceOnChange(floatValue, row.id);
                                }}
                                onFocus={() => {
                                  inpuntPriceRefs.current[index].select();
                                }}
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column fs-012 text-gray-600">
                              <NumericFormat
                                placeholder="1"
                                defaultValue={row.cantidad}
                                getInputRef={(el: any) =>
                                  (inputAmountRefs.current[index] = el)
                                }
                                thousandSeparator={false}
                                decimalSeparator={"."}
                                allowNegative={false}
                                decimalScale={1}
                                className="w-100 border-0"
                                style={{ outline: "none" }}
                                onChange={(e) => handleAmountChange(e, row.id)}
                                onFocus={() => {
                                  inputAmountRefs.current[index].select();
                                }}
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column fs-012 text-primary">
                              {`${formatPrice(
                                row.total,
                                "es-MX",
                                "MXN",
                                2,
                                2
                              )}`}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column fs-012 text-gray-600">
                              <a
                                className="badge badge-light-danger  text-hover-danger fw-bold px-4 py-3 cursor-pointer"
                                title="Eliminar producto"
                                onClick={async (event) => {
                                  deleteRowById(row.id);
                                }}
                              >
                                <KTSVG
                                  path="/media/icons/duotune/general/gen027.svg"
                                  className="svg-icon-4"
                                />
                              </a>
                            </div>
                          </div>
                        </td>
                      </>
                    ) : null}
                  </tr>
                </>
              ))
            ) : (
              <tr>
                <td colSpan={9} className="text-center text-muted">
                  Sin productos
                </td>
              </tr>
            )}

            <tr>
              <td colSpan={9}>
                <div className="d-flex align-items-center">
                  <div
                    className="btn btn-light-primary btn-sm mb-10"
                    onClick={AddRow}
                  >
                    <KTSVG
                      path="/media/icons/duotune/arrows/arr075.svg"
                      className="svg-icon-5"
                    />
                    Agregar Producto
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="row">
        <div className="col-md-6"></div>
        <div className="col-md-6">
          {dataMemo.length && dataMemo[0].id ? (
            <div className="d-flex flex-stack bg-primary rounded-3 p-6 mb-11">
              <div className="fs-6 fw-bold text-white">
                <span className="d-block mb-2">Cantidad de Productos</span>
                <span className="d-block fs-2qx lh-1">Total</span>
              </div>

              <div className="fs-6 fw-bold text-white text-end">
                <span className="d-block mb-2" data-kt-pos-element="amount">
                  {totalAmount}
                </span>

                <span
                  className="d-block fs-2qx lh-1"
                  data-kt-pos-element="grant-total"
                >
                  <CountUp
                    end={totalPriceProducts}
                    duration={1}
                    prefix="$"
                    decimals={2}
                  />
                </span>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export { ProductsList };
