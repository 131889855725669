import { FC } from "react";
import { PageTitle, PageLink } from "../../../../rbts/layout/core";
import { View } from "./components/View";

const widgetsBreadCrumbs: Array<PageLink> = [];

const DayCutReportPage: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={widgetsBreadCrumbs}>Reporte corte por día</PageTitle>
      <View />
    </>
  );
};

export { DayCutReportPage };
