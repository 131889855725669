import React, { useState } from "react";
import {
  KTSVG,
  LoadingGrid,
  TableList,
  formatPrice,
} from "../../../../rbts/helpers";
import { useGetAllConciliations } from "../hooks/ConsilationHook";
import { DetailConciliationModal } from "../_modals/DetailConciliationModal";
import { PdfPreviewConciliationModal } from "../_modals/PdfPreviewConciliationModal";
type Props = {
  reloadGrid: number;
  strDate: string;
};

const ConciliationGrid: React.FC<Props> = ({ reloadGrid, strDate }) => {
  const { conciliationsList, loadingRequest } = useGetAllConciliations();
  const [showModalDetail, setShowModalDetail] = useState<boolean>(false);
  const [conciliationId, setConciliationId] = useState<number>(0);
  const [showModalPdf, setShowModalPdf] = useState<boolean>(false);

  const dropdownRender = (data: any) => {
    return (
      <>
        <div className="d-flex justify-content-end flex-shrink-0">
          <div className="text-center">
            <a
              className="btn btn-primary btn-sm svg-icon-1"
              href="#/"
              onClick={() => {
                setShowModalDetail(!showModalDetail);
                const { id } = data;
                setConciliationId(id);
              }}
            >
              Ver
            </a>
          </div>

          <div className="text-center">
            <a
              className="btn  btn-sm  ms-4 "
              href="#/"
              title="Descargar pdf"
              onClick={() => {
                setShowModalPdf(!showModalPdf);
                const { id } = data;
                setConciliationId(id);
              }}
            >
              <KTSVG
                path="/media/icons/duotune/files/fil029.svg"
                className="svg-icon-1 svg-icon-primary"
              />
            </a>
          </div>
        </div>
      </>
    );
  };

  const columns: any = [
    {
      id: "folio",
      name: <div className="fw-bolder text-muted me-5">Folio</div>,
      selector: (row: any) => row.folio,
      cell: (row: any) => (
        <>
          <span className="text-primary">
            {row.folio ? row.folio : "No registrado"}
          </span>
        </>
      ),
      sortable: false,
      width: "25%",
    },
    {
      id: "punto_venta",
      name: <div className="fw-bolder text-muted me-5">Nombre del puesto</div>,
      cell: (row: any) => (
        <>
          <span>
            {row.punto_venta ? row.punto_venta?.nombre : "No registrado"}
          </span>
        </>
      ),
      sortable: false,
      width: "20%",
    },
    {
      id: "proveedor",
      name: <div className="fw-bolder text-muted me-5">Proveedor</div>,
      cell: (row: any) => (
        <>
          <span>{"No registrado"}</span>
        </>
      ),
      sortable: false,
      width: "20%",
    },
    {
      id: "producto",
      name: <div className="fw-bolder text-muted me-5 ">Saldo Electrónico</div>,
      cell: (row: any) => (
        <>
          <span className="text-success">
            {row.monto
              ? formatPrice(
                  row.total_a_pagar ? row.total_a_pagar : 0,
                  "es-MX",
                  "MXN",
                  2,
                  2
                )
              : "N/A"}
          </span>
        </>
      ),
      sortable: false,
      width: "20%",
    },
    {
      id: "acciones",
      name: <div className="fw-bolder text-muted me-5"></div>,
      cell: (row: any) => dropdownRender(row),
      sortable: false,
      width: "10%",
    },
  ];

  return (
    <>
      <div className="row gy-5 g-xl-8">
        <div className="col-xl-12">
          {loadingRequest ? (
            <LoadingGrid text="Cargando..."></LoadingGrid>
          ) : (
            <TableList data={conciliationsList} columns={columns}></TableList>
          )}
        </div>
      </div>
      <DetailConciliationModal
        strDate={strDate}
        show={showModalDetail}
        concilation={conciliationId}
        handleClose={() => {
          setShowModalDetail(false);
          setConciliationId(0);
        }}
      ></DetailConciliationModal>
      <PdfPreviewConciliationModal
        strDate={strDate}
        show={showModalPdf}
        concilation={conciliationId}
        handleClose={() => {
          setShowModalPdf(false);
          setConciliationId(0);
        }}
      ></PdfPreviewConciliationModal>
    </>
  );
};

export { ConciliationGrid };
