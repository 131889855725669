import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { NumericFormat } from 'react-number-format';
import { useEnlistmentTicket, useGetTicketByFolio } from '../hooks';
import { cleanPhoneNumber, maskPhoneNumber } from '../../../../../rbts/helpers';
import Swal from 'sweetalert2';

type Props = {};

const View: React.FC<Props> = ({}) => {
  const validationSearchSchema = Yup.object().shape({
    ticketcash: Yup.string().required('Campo requerido'),
  });
  const validationEnlistmentSchema = Yup.object().shape({
    nombre: Yup.string().required('Campo requerido'),
    telefono_format: Yup.string()
      .required('Campo requerido')
      .min(10, 'Formato de teléfono inválido'),
  });

  // OBTIENE LA INFORMACIÓN POR EL TICKETCASH
  const [filterInfoTicket, setFilterInfoTicket] = useState({
    ticketcash: '',
    random: 0,
  });
  const { ticket, setTicket, loading } = useGetTicketByFolio(filterInfoTicket);
  const {
    enlistmentTicket,
    loading: loadingET,
    setLoading,
    isSuccess,
    needConfirm,
    setNeedConfirm,
    textNeedConfirm,
  } = useEnlistmentTicket();

  const FormSearch = useFormik({
    initialValues: {
      ticketcash: '',
      random: 0,
    },
    validationSchema: validationSearchSchema,
    enableReinitialize: true,
    onSubmit: (values: any, { setSubmitting }) => {
      setTicket(null);
      EnlistmentForm.resetForm();
      const { ticketcash } = values;
      setFilterInfoTicket({
        ticketcash: ticketcash,
        random: Math.random() * 40,
      });
    },
  });

  const EnlistmentForm = useFormik({
    initialValues: {
      folio: '',
      nombre: '',
      telefono: '',
      telefono_format: '',
    },
    validationSchema: validationEnlistmentSchema,
    enableReinitialize: true,
    onSubmit: (values: any, { setSubmitting }) => {
      if (needConfirm) {
        const newValues = { ...values, enrolar: true };
        enlistmentTicket(newValues);
      } else {
        const newValues = { ...values, enrolar: false };
        enlistmentTicket(newValues);
      }
    },
  });

  const changeTicket = (e: any) => {
    if (e) {
      const { value } = e.target;
      FormSearch.setFieldValue('ticketcash', value);
    } else {
      FormSearch.setFieldValue('ticketcash', 0);
    }
  };

  const handlePhoneChange = (values: any) => {
    const valueNumber = Number(values.target.value.replace(/\s/g, ''));
    if (valueNumber) {
      const value = values.target.value.toString();
      const maskedPhoneNumber = maskPhoneNumber(value);
      EnlistmentForm.setFieldValue('telefono', maskedPhoneNumber);
      EnlistmentForm.setFieldValue(
        'telefono_format',
        cleanPhoneNumber(maskedPhoneNumber)
      );
    } else {
      EnlistmentForm.setFieldValue('telefono', '');
      EnlistmentForm.setFieldValue('telefono_format', '');
    }
  };

  useEffect(() => {
    if (ticket) {
      EnlistmentForm.setFieldValue('folio', ticket.folio);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticket]);

  useEffect(() => {
    if (isSuccess) {
      setTicket(null);
      EnlistmentForm.resetForm();
      FormSearch.resetForm();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (needConfirm) {
      Swal.fire({
        title: 'Se encontró un cliente con ese número',
        html: `
        <div className="text-gray-600">
          ${textNeedConfirm}
        </div>
        `,
        showCancelButton: true,
        padding: '2em',
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Aceptar',
        reverseButtons: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-light me-3',
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          EnlistmentForm.submitForm();
        } else {
          setLoading(false);
          setNeedConfirm(false);
        }
      });
    }
  }, [needConfirm]);

  return (
    <>
      <div className="d-flex flex-column flex-xl-row">
        <div className="flex-lg-row-fluid">
          <div className="card mb-6 mb-xl-9">
            <div className="card-header border-0">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bold text-gray-800">
                  Datos del ticketcash
                </span>
              </h3>
            </div>
            <div className="card-body">
              <form
                id="formBoxDetail"
                className="form"
                action="#"
                noValidate
                onSubmit={FormSearch.handleSubmit}
              >
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                    <div className="w-100 pe-5">
                      <div className="w-100 d-flex">
                        <label className="required form-label w-auto">
                          Ticketcash:{' '}
                        </label>
                        <div className="w-auto">
                          {FormSearch.touched.ticketcash &&
                            FormSearch.errors.ticketcash && (
                              <div className="fv-plugins-message-container text-danger w-auto ms-5">
                                <span role="alert">
                                  {FormSearch.errors.ticketcash}
                                </span>
                              </div>
                            )}
                        </div>
                      </div>
                      <div className="w-100 d-flex">
                        <NumericFormat
                          placeholder="Ingrese no. ticketcash"
                          thousandSeparator={false}
                          value={FormSearch.values.ticketcash}
                          allowLeadingZeros
                          decimalSeparator={'.'}
                          allowNegative={false}
                          decimalScale={0}
                          onChange={changeTicket}
                          className="form-control w-300px"
                        />
                        <button
                          type="submit"
                          title="Buscar"
                          onClick={() => {}}
                          className="btn btn-primary w-200px ms-5"
                          disabled={loading}
                        >
                          {!loading && (
                            <span className="indicator-label">Buscar</span>
                          )}
                          {loading && (
                            <span
                              className="indicator-progress"
                              style={{ display: 'block' }}
                            >
                              Espere por favor
                              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          {ticket && (
            <div className="card mb-6 mb-xl-9">
              <div className="card-header border-0">
                <h3 className="card-title align-items-start flex-column">
                  <span className="card-label fw-bold text-gray-800">
                    Enrolamiento
                  </span>
                </h3>
              </div>
              <div className="card-body">
                <form
                  id="formBoxDetail"
                  className="form"
                  action="#"
                  noValidate
                  onSubmit={EnlistmentForm.handleSubmit}
                >
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-4 col-xl-3 col-xxl-3">
                      <div className="w-100 pe-5">
                        <div className="w-100 d-flex">
                          <label className="required form-label w-auto">
                            Nombre:{' '}
                          </label>
                          <div className="w-auto">
                            {EnlistmentForm.touched.nombre &&
                              EnlistmentForm.errors.nombre && (
                                <div className="fv-plugins-message-container text-danger w-auto ms-5">
                                  <span role="alert">
                                    {EnlistmentForm.errors.nombre}
                                  </span>
                                </div>
                              )}
                          </div>
                        </div>
                        <div className="w-100 d-flex">
                          <input
                            type="text"
                            {...EnlistmentForm.getFieldProps('nombre')}
                            placeholder="Ingrese el nombre del cliente"
                            className="form-control w-300px"
                          ></input>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-4 col-xl-3 col-xxl-3">
                      <div className="w-100 pe-5">
                        <div className="w-100 d-flex">
                          <label className="required form-label w-auto">
                            Teléfono:{' '}
                          </label>
                          <div className="w-auto">
                            {EnlistmentForm.touched.telefono_format &&
                              EnlistmentForm.errors.telefono_format && (
                                <div className="fv-plugins-message-container text-danger w-auto ms-5">
                                  <span role="alert">
                                    {EnlistmentForm.errors.telefono_format}
                                  </span>
                                </div>
                              )}
                          </div>
                        </div>
                        <div className="w-100 d-flex">
                          <input
                            className="form-control w-300px"
                            value={EnlistmentForm.values.telefono}
                            placeholder="Ingresa el teléfono"
                            prefix=""
                            onChange={handlePhoneChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-4 col-xl-3 col-xxl-3">
                      <div className="w-100 pe-5 mt-8">
                        <div className="w-100 d-flex">
                          <button
                            type="submit"
                            title="Iniciar día"
                            onClick={() => {}}
                            className="btn btn-primary w-200px ms-5"
                            disabled={loadingET}
                          >
                            {!loadingET && (
                              <span className="indicator-label">Enrolar</span>
                            )}
                            {loadingET && (
                              <span
                                className="indicator-progress"
                                style={{ display: 'block' }}
                              >
                                Espere por favor
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                              </span>
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export { View };
