import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  closeCurrentDay,
  executeConciliation,
  getAllConciliations,
  getAllSuppliersOpened,
  getConciliationById,
  getConciliationBySupplier,
} from "../../../service/conciliation/Conciliation";
import { ConciliationModel } from "../models/ConciliationModel";
import { SimpleAlert } from "../../../../rbts/helpers";
import { SelectFormat } from "../../../../rbts/helpers/models/GenericModel";
import { StartShiftModel } from "../../start_shift/models/StartShiftModel";
import { getAllShiftSupplier } from "../../../service/start_shift/StartShift";

export const useGetAllConciliations = () => {
  const [conciliationsList, setConciliationsList] = useState<
    ConciliationModel[]
  >([]);
  const [loadingRequest, setLoadingRequest] = useState<boolean>(true);
  const history = useHistory();

  useEffect(() => {
    const fetchPost = async () => {
      const res = await getAllConciliations()
        .then()
        .catch((error) => {
          history.push("/error/500");
        });
      if (res) {
        setConciliationsList(res.rows);
        setLoadingRequest(false);
      }
    };
    fetchPost();
  }, []);

  return { conciliationsList, loadingRequest };
};
export const useGetConciliationBySupplier = (supplierId: number) => {
  const [conciliationSupplier, setConciliationSupplier] = useState<
    ConciliationModel[]
  >([]);
  const [loadingRequest, setLoadingRequest] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const history = useHistory();

  useEffect(() => {
    const fetchPost = async () => {
      const res = await getConciliationBySupplier(supplierId)
        .then()
        .catch((err: any) => {
          setError(true);
          let resMessageToast: string = "";
          if (err.response && err.response.data && err.response.data.message) {
            resMessageToast = err.response.data.message;
          } else {
            resMessageToast =
              "Hubo un problema para realizar la búsqueda, intentalo nuevamente.";
          }
          SimpleAlert(resMessageToast, 3000, "error");
          throw resMessageToast;
        });
      if (res) {
        setConciliationSupplier(res);
        setLoadingRequest(false);
        setError(false);
      }
    };

    if (supplierId) {
      fetchPost();
    }
  }, [supplierId]);

  return { conciliationSupplier, loadingRequest, error };
};

export const useExecuteConciliation = () => {
  const [loadingConciliationExecute, setLoadingConciliationExecute] =
    useState<boolean>(false);

  const generateConciliation = async (body: any) => {
    setLoadingConciliationExecute(true);
    try {
      await executeConciliation(body);
      setLoadingConciliationExecute(false);
      SimpleAlert(
        "La conciliación se ha generado correctamente",
        3000,
        "success"
      );
    } catch (err: any) {
      setLoadingConciliationExecute(false);
      let resMessageToast: string = "";
      if (err.response && err.response.data && err.response.data.message) {
        resMessageToast = err.response.data.message;
      } else {
        resMessageToast =
          "Hubo un problema para realizar la conciliación, intentalo nuevamente.";
      }
      SimpleAlert(resMessageToast, 3000, "error");
      throw resMessageToast;
    }
  };

  return { generateConciliation, loadingConciliationExecute };
};

export const useGetConciliationById = (conciliationId: number) => {
  const [conciliationDetail, setConciliationDetail] = useState<
    ConciliationModel[]
  >([]);
  const [loadingRequest, setLoadingRequest] = useState<boolean>(true);
  const history = useHistory();

  useEffect(() => {
    const fetchPost = async () => {
      const res = await getConciliationById(conciliationId)
        .then()
        .catch((error) => {
          history.push("/error/500");
        });
      if (res) {
        setConciliationDetail(res);
        setLoadingRequest(false);
      }
    };

    if (conciliationId) {
      fetchPost();
    }
  }, [conciliationId]);

  return { conciliationDetail, loadingRequest };
};

export const useGetAllShiftSupplier = () => {
  const [shiftList, setShiftList] = useState<StartShiftModel[]>([]);
  const [loadingRequest, setLoadingRequest] = useState<boolean>(true);
  const history = useHistory();

  useEffect(() => {
    const fetchPost = async () => {
      const res = await getAllShiftSupplier()
        .then()
        .catch((error) => {
          history.push("/error/500");
        });
      if (res) {
        const selectOptions = res.rows.map((option: StartShiftModel) => ({
          value: option.id,
          label: `${option.nombre_punto_venta} `,
          conciliado: option.isConciliado,
        }));
        setShiftList(selectOptions);
        setLoadingRequest(false);
      }
    };
    fetchPost();
  }, []);

  return { shiftList, loadingRequest };
};

export const useExecuteCloseCurrentDay = () => {
  const [loadingCloseDay, setLoadingCloseDay] = useState<boolean>(false);

  const closeDay = async (dayId: number) => {
    setLoadingCloseDay(true);
    try {
      await closeCurrentDay(dayId);
      setLoadingCloseDay(false);
      SimpleAlert("El día actual se cerro correctamente", 3000, "success");
    } catch (err: any) {
      setLoadingCloseDay(false);
      let resMessageToast: string = "";
      if (err.response && err.response.data && err.response.data.message) {
        resMessageToast = err.response.data.message;
      } else {
        resMessageToast =
          "Hubo un problema para cerrar el día, intentalo nuevamente.";
      }
      SimpleAlert(resMessageToast, 3000, "error");
      throw resMessageToast;
    }
  };

  return { closeDay, loadingCloseDay };
};

export const useSelectSuppliersOpened = () => {
  const [suppliers, setSuppliers] = useState<SelectFormat[]>([]);
  const [loadingRequest, setLoadingRequest] = useState<boolean>(true);
  const history = useHistory();
  let arr: any = [];

  useEffect(() => {
    const fetchPost = async () => {
      const res = await getAllSuppliersOpened()
        .then()
        .catch((error) => {
          history.push("/error/500");
        });
      if (res) {
        setSuppliers(res);
        setLoadingRequest(false);
      }
    };

    fetchPost();
  }, []);

  return { suppliers, loadingRequest };
};
