import { useState, useEffect } from 'react';
import { SimpleAlert } from '../../../../../rbts/helpers';
import { createCancelTicket, getBalanceRecovery } from '../../../../service/enlistment';
import { BalanceRecovery } from '../models/BalanceRecoveryModel';
import { useHistory } from 'react-router-dom';

export const useGetBalanceRecovery = (filter: any, reload: number) => {
  const [balanceRecovery, setBalanceRecovery] = useState<BalanceRecovery | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchTicket = async () => {
      setLoading(true);
      try {
        const data = await getBalanceRecovery(filter);
        if (data) {
          setBalanceRecovery(data);
        }
      } catch (error: any) {
        let errorMessage = 'Ha ocurrido un error al obtener el ticket.';
        if (error.response && error.response.data && error.response.data.message) {
          errorMessage = error.response.data.message;
        }
        SimpleAlert(errorMessage, 3000, 'error');
      } finally {
        setLoading(false);
      }
    };

    if (filter.folio || filter.telefono) {
      fetchTicket();
    }

  }, [filter, reload]);

  return { balanceRecovery, setBalanceRecovery, loading };
};


export const useCancelTicket = () => {

  const [loading, setLoading] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const history = useHistory();
  const cancelTicket = async (values: any) => {
    setLoading(true);
    setIsSuccess(false);
    try {
      const response = await createCancelTicket(values);
      if(response){
        SimpleAlert(response.data.message, 3000, 'success');
        setLoading(false);
        setIsSuccess(true);
      }
    } catch (error: any) {
      if (error && error.response) {
        const { message } = error.response.data;
        SimpleAlert(message, 3000, 'error');
        setLoading(false);
      } else {
        history.push('/error/500');
      }
    }
  };
  return { cancelTicket, loading, isSuccess  };
};

