import React, { useRef, useState, useEffect } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { DetalleConciliacion } from "../models/ConciliationModel";
import { formatPrice } from "../../../../rbts/helpers";
import CountUp from "react-countup";

type Props = {
  strDate: string;
  data: any;
  handleClose: (updateGrid: boolean) => void;
};

const ConcilationPdf: React.FC<Props> = ({ strDate, data, handleClose }) => {
  const [loadingDownload, setLoadingDownload] = useState<boolean>(false);
  const contentRef = useRef<HTMLDivElement>(null);
  const footerRef = useRef<HTMLDivElement>(null);
  const [totalAmount, setTotalAmount] = useState<number>(0);
  const [totalElectronicBalance, setTotalElectronicBalance] =
    useState<number>(0);
  const [totalBank, setTotalBank] = useState<number>(0);
  const [totalsupplier, setTotalsupplier] = useState<number>(0);
  const [totalRawMaterial, setTotalRawMaterial] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [splitBank, setSplitBank] = useState<number>(0);
  const [splitSailPoint, setSplitSailPoint] = useState<number>(0);
  const [supplierName, setSupplierName] = useState<string>("");
  const [user, setUser] = useState<string>("");
  const [detail, setDetail] = useState<any>([]);

  // const generatePDF = () => {
  //   setLoadingDownload(true);

  //   const content = contentRef.current;
  //   if (content) {
  //     html2canvas(content, {
  //       scrollY: -window.scrollY,
  //       useCORS: true,
  //     }).then((canvas) => {
  //       const imgData = canvas.toDataURL("image/png");
  //       const imgWidth = 208;
  //       const imgHeight = (canvas.height * imgWidth) / canvas.width;
  //       const pageHeight = 295;
  //       const pdf = new jsPDF({
  //         format: "letter",
  //       });

  //       let position = 0;
  //       let totalPages = 1;

  //       if (imgHeight > pageHeight) {
  //         let heightLeft = imgHeight;
  //         while (heightLeft > 0) {
  //           position = (imgHeight - heightLeft) % pageHeight;
  //           pdf.addImage(
  //             imgData,
  //             "PNG",
  //             0,
  //             position,
  //             imgWidth,
  //             Math.min(pageHeight, heightLeft)
  //           );
  //           heightLeft -= pageHeight;
  //           if (heightLeft > 0) {
  //             totalPages++;
  //             pdf.addPage();
  //           }
  //         }
  //       } else {
  //         pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
  //       }

  //       pdf.save(`Conciliación-${strDate}.pdf`);
  //     });

  //     // const doc = new jsPDF("p", "pt", "letter");
  //     // doc.html(ReactDOMServer.renderToString(), {
  //     //   callback: function (doc) {
  //     //     doc.save("sample.pdf");
  //     //   },
  //     // });
  //     setLoadingDownload(false);

  //     handleClose(false);
  //   }
  // };

  const itemsPerPage = 10;

  // const detailData = [
  //   {
  //     producto_nombre: "Producto 1",
  //     precio: 10,
  //     cantidad: 5,
  //     saldo_electronico: 100,
  //     monto_banco: 50,
  //     monto_punto_venta: 200,
  //   },
  //   {
  //     producto_nombre: "Producto 2",
  //     precio: 20,
  //     cantidad: 3,
  //     saldo_electronico: 150,
  //     monto_banco: 70,
  //     monto_punto_venta: 180,
  //   },
  //   {
  //     producto_nombre: "Producto 2",
  //     precio: 20,
  //     cantidad: 3,
  //     saldo_electronico: 150,
  //     monto_banco: 70,
  //     monto_punto_venta: 180,
  //   },
  //   {
  //     producto_nombre: "Producto 2",
  //     precio: 20,
  //     cantidad: 3,
  //     saldo_electronico: 150,
  //     monto_banco: 70,
  //     monto_punto_venta: 180,
  //   },
  //   {
  //     producto_nombre: "Producto 2",
  //     precio: 20,
  //     cantidad: 3,
  //     saldo_electronico: 150,
  //     monto_banco: 70,
  //     monto_punto_venta: 180,
  //   },
  //   {
  //     producto_nombre: "Producto 2",
  //     precio: 20,
  //     cantidad: 3,
  //     saldo_electronico: 150,
  //     monto_banco: 70,
  //     monto_punto_venta: 180,
  //   },
  //   {
  //     producto_nombre: "Producto 2",
  //     precio: 20,
  //     cantidad: 3,
  //     saldo_electronico: 150,
  //     monto_banco: 70,
  //     monto_punto_venta: 180,
  //   },
  //   {
  //     producto_nombre: "Producto 2",
  //     precio: 20,
  //     cantidad: 3,
  //     saldo_electronico: 150,
  //     monto_banco: 70,
  //     monto_punto_venta: 180,
  //   },
  //   {
  //     producto_nombre: "Producto 2",
  //     precio: 20,
  //     cantidad: 3,
  //     saldo_electronico: 150,
  //     monto_banco: 70,
  //     monto_punto_venta: 180,
  //   },
  //   // {
  //   //   producto_nombre: "Producto 2",
  //   //   precio: 20,
  //   //   cantidad: 3,
  //   //   saldo_electronico: 150,
  //   //   monto_banco: 70,
  //   //   monto_punto_venta: 180,
  //   // },
  //   // {
  //   //   producto_nombre: "Producto 2",
  //   //   precio: 20,
  //   //   cantidad: 3,
  //   //   saldo_electronico: 150,
  //   //   monto_banco: 70,
  //   //   monto_punto_venta: 180,
  //   // },
  //   // {
  //   //   producto_nombre: "Producto 2",
  //   //   precio: 20,
  //   //   cantidad: 3,
  //   //   saldo_electronico: 150,
  //   //   monto_banco: 70,
  //   //   monto_punto_venta: 180,
  //   // },
  //   // {
  //   //   producto_nombre: "Producto 2",
  //   //   precio: 20,
  //   //   cantidad: 3,
  //   //   saldo_electronico: 150,
  //   //   monto_banco: 70,
  //   //   monto_punto_venta: 180,
  //   // },
  //   // {
  //   //   producto_nombre: "Producto 2",
  //   //   precio: 20,
  //   //   cantidad: 3,
  //   //   saldo_electronico: 150,
  //   //   monto_banco: 70,
  //   //   monto_punto_venta: 180,
  //   // },
  //   // {
  //   //   producto_nombre: "Producto 2",
  //   //   precio: 20,
  //   //   cantidad: 3,
  //   //   saldo_electronico: 150,
  //   //   monto_banco: 70,
  //   //   monto_punto_venta: 180,
  //   // },
  //   // {
  //   //   producto_nombre: "Producto 2",
  //   //   precio: 20,
  //   //   cantidad: 3,
  //   //   saldo_electronico: 150,
  //   //   monto_banco: 70,
  //   //   monto_punto_venta: 180,
  //   // },
  //   // {
  //   //   producto_nombre: "Producto 2",
  //   //   precio: 20,
  //   //   cantidad: 3,
  //   //   saldo_electronico: 150,
  //   //   monto_banco: 70,
  //   //   monto_punto_venta: 180,
  //   // },
  //   // {
  //   //   producto_nombre: "Producto 2",
  //   //   precio: 20,
  //   //   cantidad: 3,
  //   //   saldo_electronico: 150,
  //   //   monto_banco: 70,
  //   //   monto_punto_venta: 180,
  //   // },
  //   // {
  //   //   producto_nombre: "Producto 2",
  //   //   precio: 20,
  //   //   cantidad: 3,
  //   //   saldo_electronico: 150,
  //   //   monto_banco: 70,
  //   //   monto_punto_venta: 180,
  //   // },
  //   // Agrega más objetos según sea necesario
  // ];

  const generatePDF = () => {
    const totalPages = Math.ceil(detail?.detalle.length / itemsPerPage) || 1;
    let flagForToPrintFinalData: any;
    let yPosition = 100;
    const doc = new jsPDF({
      format: "letter",
    });
    const generatePage = (pageNumber: any) => {
      const startIndex = (pageNumber - 1) * itemsPerPage;
      const endIndex = Math.min(
        startIndex + itemsPerPage,
        detail?.detalle.length
      );

      const pageData = detail?.detalle.slice(startIndex, endIndex);

      doc.setTextColor("#4A5DDC");
      doc.setFontSize(15);
      doc.text(`Conciliación con proveedor`, 20, 30);
      const logoWidth = 40; // Ancho de la imagen del logo
      const logoHeight = 25; // Alto de la imagen del logo

      doc.addImage(
        process.env.PUBLIC_URL + "/media/logos/logo_2.png",
        "PNG",
        150,
        20,
        logoWidth,
        logoHeight
      );
      doc.setFontSize(12);
      doc.setTextColor("#000");

      doc.text(`Fecha administrativa: ${strDate}`, 20, 40);

      const cellWidth = 70;
      const cellHeight = 10;
      const startX = 20;
      const startY = 60;

      // Datos de la tabla
      const data = [
        {
          // nombrePuesto: "Nombre",
          nombre: "Nombre del puesto",
          // telefono: "Teléfono",
        },
        {
          nombre: supplierName,
          // nombrePuesto: "Sin Nombre",
          // telefono: "Sin teléfono",
        },
      ];

      // Colores para las filas
      const textColors = ["#4A5DDC", "#000"]; // Color azul para el encabezado, rojo para el resto

      // Escribir la tabla
      data.forEach((row, index) => {
        const rowY = startY + (index + 1) * cellHeight;

        // Establecer el color de texto para la fila
        doc.setTextColor(textColors[index]); // Seleccionar el color correspondiente según el índice

        // Escribir las celdas de la fila
        doc.text(row.nombre, startX, rowY);
        // doc.text(row.nombrePuesto, startX + cellWidth, rowY);
        // doc.text(row.telefono, startX + 2 * cellWidth, rowY);
      });

      // Encabezado de la tabla
      doc.setFontSize(10);
      doc.text("Productos", 20, yPosition);
      doc.text("Precio", 40, yPosition);
      doc.text("Cantidad", 60, yPosition);
      doc.text("Saldo electrónico", 80, yPosition);
      doc.text("Banco", 120, yPosition);
      doc.text("Proveedor", 140, yPosition);
      doc.text("Materia prima", 160, yPosition);
      doc.text("Total", 190, yPosition);

      doc.setTextColor("#000");
      // Contenido de la tabla
      let currentY = yPosition + 10;
      pageData.forEach((item: any, index: any) => {
        doc.setFontSize(10);
        doc.text(item.producto_nombre, 20, currentY);
        doc.text(
          formatPrice(
            item.precio ? item.precio : 0,
            "es-MX",
            "MXN",
            2,
            2
          ).toString(),
          40,
          currentY
        );
        doc.text(item.cantidad.toString(), 60, currentY);
        doc.text(
          formatPrice(
            item.saldo_electronico ? item.saldo_electronico : 0,
            "es-MX",
            "MXN",
            2,
            2
          ).toString(),
          80,
          currentY
        );
        doc.text(
          formatPrice(
            item.monto_banco ? item.monto_banco : 0,
            "es-MX",
            "MXN",
            2,
            2
          ).toString(),
          120,
          currentY
        );
        doc.text(
          formatPrice(
            item.monto_punto_venta ? item.monto_punto_venta : 0,
            "es-MX",
            "MXN",
            2,
            2
          ).toString(),
          140,
          currentY
        );

        currentY += 10; // Altura de fila
      });

      if (flagForToPrintFinalData === totalPages) {
        doc.setFontSize(10);
        doc.text("Total", 40, currentY);
        // CANTIDAD VALUE
        doc.text(totalAmount.toString(), 60, currentY);
        // SALDO ELECTRONICO VALUE
        doc.text(
          formatPrice(
            totalElectronicBalance ? totalElectronicBalance : 0,
            "es-MX",
            "MXN",
            2,
            2
          ).toString(),
          80,
          currentY
        );
        // BANCO VALUE
        doc.text(
          formatPrice(
            totalBank ? totalBank : 0,
            "es-MX",
            "MXN",
            2,
            2
          ).toString(),
          120,
          currentY
        );
        // PROVEEDOR VALUE

        doc.text(
          formatPrice(
            totalsupplier ? totalsupplier : 0,
            "es-MX",
            "MXN",
            2,
            2
          ).toString(),
          140,
          currentY
        );
        // MATERIA PRIMA VALUE

        doc.text(
          formatPrice(
            totalRawMaterial ? totalRawMaterial : 0,
            "es-MX",
            "MXN",
            2,
            2
          ).toString(),
          160,
          currentY
        );
        // MATERIA TOTAL

        doc.text(
          formatPrice(total ? total : 0, "es-MX", "MXN", 2, 2).toString(),
          190,
          currentY
        );

        // Linea firma proveedor
        // doc.line(20, 250, 80, 250); // (x1, y1, x2, y2)
        // Agregar nombre del proveedor
        doc.text(supplierName, 33, 260);

        // doc.text("--------------------------------------------", 150, 250); // (x1, y1, x2, y2)
        doc.text(user, 163, 260);
      }

      return doc;
    };

    for (let page = 1; page <= totalPages; page++) {
      flagForToPrintFinalData = page;
      const pdf = generatePage(page);

      if (page === totalPages) {
        pdf.save(`Conciliación${strDate}.pdf`);
        handleClose(false);
      } else {
        pdf.addPage();
      }
    }
  };

  useEffect(() => {
    if (data) {
      const {
        total_cantidad,
        total_saldo_electronico,
        total_banco,
        total_punto_venta,
        total_materia_prima,
        total_a_pagar,
        split_banco,
        split_punto_venta,
        punto_venta,
        user,
      }: any = data;
      setTotalAmount(total_cantidad);
      setTotalElectronicBalance(total_saldo_electronico);
      setTotalBank(total_banco);
      setTotalsupplier(total_punto_venta);
      setTotalRawMaterial(total_materia_prima);
      setTotal(total_a_pagar);
      setSplitBank(split_banco ? split_banco : 0);
      setSplitSailPoint(split_punto_venta ? split_punto_venta : 0);
      setSupplierName(punto_venta ? punto_venta?.nombre : "");
      setUser(user ? `${user?.first_name} ${user?.father_last_name}` : "");
      setDetail(data);
    } else {
      setDetail([]);
    }
  }, [data]);

  return (
    <>
      <div
        ref={contentRef}
        className="d-flex flex-row-fluid flex-center bg-white rounded"
      >
        <div className="w-100 h-100">
          <div className="p-0 min-h-800 ">
            <div className="mb-5 min-h-800">
              <div className="d-flex justify-content-center mt-20 mb-10">
                <div className="w-75">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="fs-1 fw-bolder text-primary">
                      Conciliación con proveedor
                      <h3 className="card-title mt-5">
                        <span className="card-label fw-bold text-gray-800">
                          Fecha administrativa:{" "}
                        </span>
                        <span className="fs-4 text-gray-700">{strDate}</span>
                      </h3>
                    </div>
                    <div>
                      <img
                        src={process.env.PUBLIC_URL + "/media/logos/logo_2.png"}
                        alt="default"
                        className="w-150px pb-4"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className=" d-flex justify-content-center ">
                <div className="col-sm-12 col-md-4 col-lg-3 col-xl-3 col-xxl-3">
                  <div className="w-100 pe-5">
                    <div className="w-100 d-flex">
                      <label className=" form-label w-auto text-primary">
                        Nombre del puesto:
                      </label>
                    </div>
                    {supplierName}
                  </div>
                </div>
                <div className="col-sm-12 col-md-4 col-lg-3 col-xl-3 col-xxl-3">
                  {/* <div className="w-100 pe-5">
                    <div className="w-100 d-flex">
                      <label className=" form-label w-auto text-primary">
                        Nombre
                      </label>
                    </div>
                    Sin nombre
                  </div> */}
                </div>
                <div className="col-sm-12 col-md-4 col-lg-3 col-xl-3 col-xxl-3">
                  {/* <div className="w-100 pe-5">
                    <div className="w-100 d-flex">
                      <label className=" form-label w-auto text-primary">
                        Teléfono
                      </label>
                    </div>
                    Sin teléfono
                  </div> */}
                </div>
              </div>

              <div className="d-flex justify-content-center mt-10">
                <div className="w-75">
                  <div className="d-flex justify-content-between align-items-center mt-9">
                    <div className="fs-1 fw-bolder text-bold">Conciliación</div>
                  </div>
                </div>
              </div>

              <div className=" d-flex justify-content-center p-10 ">
                <table className="table pe-5 ">
                  <thead>
                    <tr className="text-gray-800 fs-7">
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th className="text-primary w-100px">
                        {" "}
                        Split {`${splitSailPoint}/${splitBank}`}
                      </th>
                      <th className="w-100px text-primary">Deuda MP </th>
                    </tr>
                    <tr className="text-gray-800 fs-7">
                      <th className="w-200px text-muted">Productos</th>
                      <th className="w-100px text-muted">Precio</th>
                      <th className="w-100px text-muted">Cantidad</th>
                      <th className="w-200px text-muted">Saldo electrónico</th>
                      <th className="w-100px text-muted">Banco</th>
                      <th className="w-100px text-muted">Proveedor</th>
                      <th className="w-200px text-muted">Materia prima</th>
                      <th className="w-100px text-muted">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {detail && detail?.detalle?.length ? (
                      detail.detalle.map(
                        (item: DetalleConciliacion, index: number) => (
                          <tr key={index}>
                            <td className="fs-9">{item.producto_nombre}</td>
                            <td className="fs-9">
                              {formatPrice(
                                item.precio ? item.precio : 0,
                                "es-MX",
                                "MXN",
                                2,
                                2
                              )}
                            </td>
                            <td className="fs-9">{item.cantidad}</td>
                            <td className="fs-9">
                              {formatPrice(
                                item.saldo_electronico
                                  ? item.saldo_electronico
                                  : 0,
                                "es-MX",
                                "MXN",
                                2,
                                2
                              )}
                            </td>
                            <td className="fs-9">
                              {formatPrice(
                                item.monto_banco ? item.monto_banco : 0,
                                "es-MX",
                                "MXN",
                                2,
                                2
                              )}
                            </td>
                            <td className="fs-9">
                              {formatPrice(
                                item.monto_punto_venta
                                  ? item.monto_punto_venta
                                  : 0,
                                "es-MX",
                                "MXN",
                                2,
                                2
                              )}
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td colSpan={9} className="text-center text-muted">
                          Sin registros
                        </td>
                      </tr>
                    )}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td></td>
                      <td className="text-bold fs-6">
                        <span>Total</span>
                      </td>
                      <td>{totalAmount}</td>
                      <td className="text-bold fs-6">
                        <CountUp
                          end={totalElectronicBalance}
                          duration={1}
                          prefix="$"
                          decimals={2}
                        />
                      </td>
                      <td className="text-bold fs-6">
                        <CountUp
                          end={totalBank}
                          duration={1}
                          prefix="$"
                          decimals={2}
                        />
                      </td>
                      <td className="text-bold fs-6">
                        <CountUp
                          end={totalsupplier}
                          duration={1}
                          prefix="$"
                          decimals={2}
                        />
                      </td>
                      <td className="text-bold fs-6">
                        <CountUp
                          end={totalRawMaterial}
                          duration={1}
                          prefix="$"
                          decimals={2}
                        />
                      </td>
                      <td className="text-bold fs-6">
                        <CountUp
                          end={total}
                          duration={1}
                          prefix="$"
                          decimals={2}
                        />
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>

              <div
                className="d-flex justify-content-center mt-30 fs-5 mt-150px"
                ref={footerRef}
              >
                <div className="row w-100 d-flex">
                  <div className="space-between-center">
                    <div className="w-100 text-center ">
                      {/* <div className="signature-line mb-3"></div> */}
                      <span className="text-bold font-w-600">
                        {supplierName}
                      </span>
                      <br />
                      <span className="fs-7 text-muted">Proveedor</span>
                    </div>
                    <div className="w-100 text-center ">
                      {/* <div className="signature-line mb-3"></div> */}
                      <span className="text-bold font-w-600">{user}</span>
                      <br />
                      <span className="fs-7 text-muted">Comprador</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="btn-fixed">
            <button
              onClick={() => {
                handleClose(false);
              }}
              className="btn btn-light mt-3 "
            >
              Cancelar
            </button>

            <button
              className="btn btn-primary mt-3 ms-3"
              onClick={generatePDF}
              disabled={loadingDownload}
            >
              {!loadingDownload && (
                <span className="indicator-label">Descargar</span>
              )}
              {loadingDownload && (
                <span
                  className="indicator-progress"
                  style={{ display: "block" }}
                >
                  Espere por favor...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export { ConcilationPdf };
