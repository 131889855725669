import React, { useState } from 'react';
import { Modal } from 'react-bootstrap-v5';
import { KTSVG, SimpleAlert, formatPrice } from '../../../../../rbts/helpers';
import { useGetDetailInit } from '../hooks';

type Props = {
  show: boolean;
  id_row: number;
  handleClose: (updateGrid: boolean) => void;
};

const DetailModal: React.FC<Props> = ({ show, id_row, handleClose }) => {
  const [loading, setLoading] = useState(false);

  const [reloadDay, setReloadDay] = useState(Math.random() * 40);
  const { detail, loadingRequest } = useGetDetailInit(reloadDay, id_row);
  return (
    <Modal
      id="kt_modal_detail"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered modal-lg"
      show={show}
      onHide={() => {
        handleClose(false);
      }}
    >
      <div className="modal-content">
        <div className="modal-header ">
          <h2> Información de la caja</h2>
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            onClick={() => {
              handleClose(false);
            }}
          >
            <KTSVG
              className="svg-icon-2"
              path="/media/icons/duotune/arrows/arr061.svg"
            />
          </div>
        </div>
        <div className="modal-body">
          <div className="row mt-10">
            <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
              <div className="d-flex">
                <div className="w-75">
                  <div className="d-flex flex-stack mb-3">
                    <label className="form-label w-auto">
                      Fecha administrativa:
                    </label>

                    <div className="text-end fw-bold fs-6 text-gray-600">
                      {detail && detail.dia_venta
                        ? detail.dia_venta.fecha
                        : 'Sin registro'}
                    </div>
                  </div>
                  <div className="d-flex flex-stack mb-3">
                    <label className="form-label w-auto">Caja:</label>

                    <div className="text-end fw-bold fs-6 text-gray-600">
                      {detail && detail.punto_venta
                        ? detail.punto_venta.nombre
                        : 'Sin registro'}
                    </div>
                  </div>
                  <div className="d-flex flex-stack mb-3">
                    <label className="form-label w-auto">Cajero:</label>

                    <div className="text-end fw-bold fs-6 text-gray-600">
                      {detail && detail.user_encargado
                        ? `${detail.user_encargado.first_name} ${detail.user_encargado.father_last_name}`
                        : 'Sin registro'}
                    </div>
                  </div>
                  <div className="d-flex flex-stack mb-3">
                    <label className="form-label w-auto">Turno:</label>

                    <div className="text-end fw-bold fs-6 text-gray-600">
                      {detail && detail.turno
                        ? detail.turno.nombre
                        : 'Sin registro'}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
              <div className="d-flex">
                <div className="w-75">
                  <div className="d-flex flex-stack mb-3">
                    <label className="form-label w-auto">
                      Efectivo inicial:
                    </label>

                    <div className="text-end fw-bold fs-6 text-gray-600">
                      {detail
                        ? formatPrice(
                            detail.efectivo_apertura,
                            'es-MX',
                            'MXN',
                            2,
                            2
                          )
                        : 'Sin registro'}
                    </div>
                  </div>

                  {detail &&
                  detail.entrega_caja_producto &&
                  detail.entrega_caja_producto.length > 0 ? (
                    detail.entrega_caja_producto.map((row: any, index: any) => (
                      <>
                        <div className="d-flex flex-stack mb-3">
                          <label className="form-label w-auto">
                            {row.producto.nombre || 'Sin nombre'}
                            {':'}
                          </label>

                          <div className="text-end fw-bold fs-6 text-gray-600">
                            {row.cantidad || 0}
                          </div>
                        </div>
                      </>
                    ))
                  ) : (
                    <div className="d-flex flex-stack mb-3">
                      <label className="form-label w-auto">
                        Sin registros:
                      </label>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="text-center pt-8">
            <button
              type="reset"
              className="btn btn-light me-3"
              data-kt-users-modal-action="cancel"
              onClick={() => {
                handleClose(false);
              }}
            >
              Cerrar
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export { DetailModal };
