import React, { useState } from "react";
import Select from "react-select";
import { styleSelect } from "../../../../rbts/assets/ts/_utils";
import { ShiftSupplierGrid } from "./ShiftSupplierGrid";
import {
  useExecuteStarShiftSuplier,
  useSelectSuppliersClosed,
} from "../hooks/StartShiftHook";
import { SimpleAlert } from "../../../../rbts/helpers";
import { useSelectSuppliers } from "../../sales/Suppliers/hooks/SalesSupplierHook";

type Props = {};

const View: React.FC<Props> = ({}) => {
  const { suppliers, loadingRequest } = useSelectSuppliers();
  const { loadingRequest: loadingStartShift, startDay } =
    useExecuteStarShiftSuplier();
  const [supplierSelected, setSupplierSelected] = useState<any | null>([]);
  const [reloadInfo, setReloadInfo] = useState<number>(Math.random() * 40);

  const [isDisabled, setIsDisabled] = useState<boolean>(true);

  const handleSupplierOnChange = (selectedOption: any) => {
    if (selectedOption) {
      setSupplierSelected(selectedOption);
      setIsDisabled(false);
    } else {
      setSupplierSelected(null);
      setIsDisabled(true);
    }
  };

  return (
    <>
      <div className="d-flex flex-column flex-xl-row">
        <div className="col-xl-12">
          <div className={`card card-xxl-stretch mb-5 mb-xl-12`}>
            <div className="d-flex flex-column flex-xl-row">
              <div className="flex-lg-row-fluid">
                <div className="card mb-6 mb-xl-9">
                  <div className="card-header border-0">
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label fw-bold text-gray-800">
                        Proveedor
                      </span>
                    </h3>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-12 col-md-4 col-lg-3 col-xl-3 col-xxl-3">
                        <div className="w-100 pe-5">
                          <div className="w-100 d-flex">
                            <label className="required form-label w-auto">
                              Nombre del puesto:{" "}
                            </label>
                          </div>
                          <Select
                            options={suppliers}
                            value={supplierSelected}
                            styles={styleSelect}
                            placeholder={"Seleccione"}
                            isSearchable={true}
                            noOptionsMessage={() => {
                              return (
                                <span>{"No se encontraron registros"}</span>
                              );
                            }}
                            onChange={handleSupplierOnChange}
                            isLoading={loadingRequest}
                            isClearable
                            backspaceRemovesValue
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                        <div className="w-100 pe-5">
                          <div className="w-100 d-flex">
                            <label className="form-label w-auto">
                              Nombre:{" "}
                            </label>
                          </div>
                          <div className="w-100 d-flex">
                            <span
                              className="form-control border-0 text-muted fs-6"
                              style={{ paddingLeft: "0px" }}
                            >
                              {supplierSelected?.label
                                ? supplierSelected.label
                                : "Seleccione el puesto"}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                        <div className="w-100 pe-5">
                          <div className="w-100 d-flex">
                            <label className="form-label w-auto">
                              Teléfono:{" "}
                            </label>
                          </div>
                          <div className="w-100 d-flex">
                            <span
                              className="form-control border-0 text-muted pl-0"
                              style={{ paddingLeft: "0px" }}
                            >
                              {supplierSelected?.label
                                ? "No registrado"
                                : "Seleccione el puesto"}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                        <div className="w-100 pe-5">
                          <button
                            type="button"
                            onClick={async () => {
                              try {
                                if (supplierSelected) {
                                  await startDay(supplierSelected?.value);
                                  setReloadInfo(Math.random() * 50);
                                } else {
                                  SimpleAlert(
                                    "Es necesario seleccionar un proveedor",
                                    3000,
                                    "error"
                                  );
                                }
                              } catch (error) {
                                console.log(error);
                              }
                            }}
                            className="btn w-200px btn-primary ms-4"
                            data-kt-users-modal-action="submit"
                            disabled={loadingStartShift || isDisabled}
                          >
                            {!loadingStartShift && (
                              <span className="indicator-label">
                                Iniciar turno
                              </span>
                            )}
                            {loadingStartShift && (
                              <span
                                className="indicator-progress"
                                style={{ display: "block" }}
                              >
                                Espere por favor...
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                              </span>
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card mb-6 mb-xl-9">
            <div className="card-header border-0">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bold text-gray-800">
                  Turnos de proveedores iniciados
                </span>
              </h3>
            </div>
            <div className="card-body">
              <ShiftSupplierGrid reloadGrid={reloadInfo}></ShiftSupplierGrid>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { View };
