import axios from "axios";
import { SelectFormat } from "../../../rbts/helpers/models/GenericModel";
const API_URL = process.env.REACT_APP_API;

export async function getAllShiftSupplier() {
  const response = await axios.get(`${API_URL}puntoVenta/diaVenta/list`);
  const data = response.data.doc.data;
  return data;
}
export async function startDaySupplier(supplierId: number) {
  const response = await axios.post(
    `${API_URL}puntoVenta/iniciarDia/${supplierId}`
  );
  const data = response.data.doc.data;
  return data;
}

export async function getAllSuppliersClosed() {
  const response = await axios.get(
    `${API_URL}puntoVenta/diaVenta/list?isAbierto=false`
  );
  const data = response.data.doc.data.rows;
  let select: SelectFormat[] = [];
  data.forEach((item: any) => {
    const selectCustom = {
      id: Number(item.id),
      value: item.id_punto_venta,
      label: `${item.nombre_punto_venta}`,
      data: item,
    };
    select.push(selectCustom);
  });

  return select;
}
