import React, { useState, useEffect } from "react";
import Select from "react-select";
import { styleSelect } from "../../../../../rbts/assets/ts/_utils";

import {
  SimpleAlert,
  TableList,
  formatDateString,
  formatPrice,
} from "../../../../../rbts/helpers";
import { useGetAllDaysSelect } from "../hooks";

type Props = {};

const TotalSections: React.FC<Props> = ({}) => {
  const { catalog: day } = useGetAllDaysSelect();
  const [strDate, setStrDate] = useState<string>("");
  const [currentDay, setCurrentDay] = useState<number>(0);
  const detalleInicial: any = [];

  return (
    <>
      <div className="card card-flush " style={{ height: "100%" }}>
        <div className="card-header pt-5">
          <h3 className="card-title text-primary fw-bold">Sección final</h3>
        </div>

        <div className="card-body pt-5">
          <div className="row mb-2">
            <div className="col-sm-3"></div>
            <div className="col-sm-3">Solicitado</div>
            <div className="col-sm-3">Entregado</div>
            <div className="col-sm-3">Diferencias</div>
          </div>
          <div className="row mb-2">
            <div className="col-sm-3 mb-1">Brazaletes</div>
            <div className="col-sm-3">e</div>
            <div className="col-sm-3">e</div>
            <div className="col-sm-3">e</div>
          </div>
          <div className="row mb-2">
            <div className="col-sm-3 mb-1">Boletos</div>
            <div className="col-sm-3">e</div>
            <div className="col-sm-3">e</div>
            <div className="col-sm-3">e</div>
          </div>
          <div className="row mb-2">
            <div className="col-sm-3 mb-1 large-text" title="Ventas efectivo">
              Ventas efectivo{" "}
            </div>
            <div className="col-sm-3">e</div>
            <div className="col-sm-3">e</div>
            <div className="col-sm-3">e</div>
          </div>
          <div className="row mb-2">
            <div
              className="col-sm-3 mb-1 large-text"
              title="Ventas con tarjeta"
            >
              Ventas con tarjeta{" "}
            </div>
            <div className="col-sm-3">e</div>
            <div className="col-sm-3">e</div>
            <div className="col-sm-3">e</div>
          </div>
        </div>
      </div>
    </>
  );
};

export { TotalSections };
