import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';
import { NumericFormat } from 'react-number-format';
import { styleSelect } from '../../../../../rbts/assets/ts/_utils';
import {
  useBoxDelivery,
  useGetAllCashierUserSelect,
  useGetAllDaysSelect,
  useGetAllPOSSelect,
  useGetAllProducts,
  useGetAllShiftsSelect,
  useGetBoxesStarted,
  useInitDayDelivery,
} from '../hooks';
import { ProductDetailModel } from '../models/ProductModel';
import {
  SimpleAlert,
  TableList,
  formatDateString,
  formatPrice,
} from '../../../../../rbts/helpers';
import { DetailModal } from '../_modals/DetailModal';

type Props = {};

const validationSchema = Yup.object().shape({
  id_punto_venta: Yup.number()
    .min(1, 'Campo requerido')
    .required('Campo requerido'),
  id_turno: Yup.number().min(1, 'Campo requerido').required('Campo requerido'),
  id_usuario_encargado: Yup.number()
    .min(1, 'Campo requerido')
    .required('Campo requerido'),
  efectivo_apertura: Yup.number()
    .min(1, 'Campo requerido')
    .required('Campo requerido'),
  id_dia: Yup.number().min(1, 'Campo requerido').required('Campo requerido'),
});

const View: React.FC<Props> = ({}) => {
  const [loadingForm, setLoadingForm] = useState<boolean>(false);
  const [row_id, setRowId] = useState<number>(0);
  const [showModal, setShowModal] = useState<boolean>(false);

  const [currentDayId, setCurrentDayId] = useState<number>(0);
  const [existDay, setExistDay] = useState<boolean>(false);
  const [textDay, setTextDay] = useState<string>('');

  const [selectedOptionShifts, setSelectedOptionShifts] = useState<any | null>(
    null
  );
  const [reloadShifts, setReloadShifts] = useState(Math.random() * 40);

  const { catalog: shifts, loadingRequest: loadingSHI } =
    useGetAllShiftsSelect(reloadShifts);

  const [selectedOptionPOS, setSelectedOptionPOS] = useState<any | null>(null);
  const [reloadPOS, setReloadPOS] = useState(Math.random() * 40);
  const { catalog: POS, loadingRequest: loadingPOS } =
    useGetAllPOSSelect(reloadPOS);

  const [selectedOptionUser, setSelectedOptionUser] = useState<any | null>(
    null
  );
  const [reloadUsers, setReloadUsers] = useState(Math.random() * 40);
  const { catalog: users, loadingRequest: loadingUsers } =
    useGetAllCashierUserSelect(reloadUsers);

  const [reloadDay, setReloadDay] = useState(Math.random() * 40);
  const { catalog: day, loadingRequest: loadingDay } =
    useGetAllDaysSelect(reloadDay);

  const [reloadProducts, setReloadProducts] = useState(Math.random() * 40);

  const { productList, loadingRequest: loadingPRO } =
    useGetAllProducts(reloadProducts);
  const [productListMemo, setProductListMemo] = useState<ProductDetailModel[]>(
    []
  );

  const [reloadBoxes, setReloadBoxes] = useState(Math.random() * 40);
  const { boxList, loadingRequest: loadingBL } = useGetBoxesStarted(
    reloadBoxes,
    currentDayId
  );

  const columns: any = [
    {
      id: 'caja',
      name: <div className="fw-bolder text-muted me-5">Caja</div>,
      selector: (row: any) => row?.punto_venta?.nombre,
      sortable: true,
      width: '20%',
    },
    {
      id: 'cajero',
      name: <div className="fw-bolder text-muted me-5">Cajero</div>,
      selector: (row: any) => row?.user_encargado?.nombre_completo,
      sortable: true,
      width: '25%',
    },
    {
      id: 'turno',
      name: <div className="fw-bolder text-muted me-5">Turno</div>,
      selector: (row: any) => row?.turno?.nombre,
      sortable: true,
      width: '20%',
    },
    {
      id: 'efectivo_inicial',
      name: <div className="fw-bolder text-muted me-5">Efectivo inicial</div>,
      selector: (row: any) =>
        `${formatPrice(row?.efectivo_apertura || 0, 'es-MX', 'MXN', 2, 2)}`,
      sortable: true,
      width: '25%',
    },
    {
      id: 'acciones',
      name: <div className="fw-bolder text-muted me-5"></div>,
      cell: (row: any) => {
        return (
          <>
            <div className="text-center">
              <button
                className="btn btn-sm btn-primary fw-bold"
                onClick={() => {
                  setRowId(row.id);
                  setShowModal(!showModal);
                }}
              >
                Ver
              </button>
            </div>
          </>
        );
      },
      sortable: false,
      width: '10%',
    },
  ];

  const { boxDelivery, loading: loadingRequest, isSuccess } = useBoxDelivery();
  const {
    initDayDelivery,
    loading: loadingID,
    isSuccess: isSuccessID,
    setLoading,
  } = useInitDayDelivery();

  const Form = useFormik({
    initialValues: {
      id_punto_venta: 0,
      id_turno: 0,
      id_usuario_encargado: 0,
      efectivo_apertura: 0,
      id_dia: 0,
      productos: [],
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values: any, { setSubmitting }) => {
      const products = productsFilter();
      if (products.length) {
        setLoadingForm(true);
        values.productos = products;
        boxDelivery(values);
      } else {
        SimpleAlert(
          'Agregue la cantidad de los productos a entregar',
          3000,
          'error'
        );
      }
    },
  });

  const changePOS = (selectedOption: any) => {
    if (selectedOption) {
      const { value } = selectedOption;
      setSelectedOptionPOS(selectedOption);
      Form.setFieldValue('id_punto_venta', value);
    } else {
      setSelectedOptionPOS(null);
      Form.setFieldValue('id_punto_venta', 0);
    }
  };

  const changeShifts = (selectedOption: any) => {
    if (selectedOption) {
      const { value } = selectedOption;
      setSelectedOptionShifts(selectedOption);
      Form.setFieldValue('id_turno', value);
    } else {
      setSelectedOptionShifts(null);
      Form.setFieldValue('id_turno', 0);
    }
  };

  const changeUsers = (selectedOption: any) => {
    if (selectedOption) {
      const { value } = selectedOption;
      setSelectedOptionUser(selectedOption);
      Form.setFieldValue('id_usuario_encargado', value);
    } else {
      setSelectedOptionUser(null);
      Form.setFieldValue('id_usuario_encargado', 0);
    }
  };

  const changeCash = (values: any) => {
    const { floatValue } = values;
    Form.setFieldValue('efectivo_apertura', floatValue || 0);
  };

  const changeAmount = (amount: number, id: number) => {
    setProductListMemo((prevDataMemo) => {
      const updatedDataMemo = prevDataMemo.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            cantidad: amount,
          };
        }
        return item;
      });
      return updatedDataMemo;
    });
  };

  const productsFilter = () => {
    return productListMemo.filter(
      (product) => product.cantidad != null && product.cantidad > 0
    );
  };

  const initDay = () => {
    setLoading(true);
    setTimeout(() => {
      initDayDelivery();
    }, 2000);
  };

  const cleanForm = () => {
    setSelectedOptionShifts(null);
    setSelectedOptionPOS(null);
    setSelectedOptionUser(null);
    setReloadPOS(Math.random() * 40);
    setReloadDay(Math.random() * 40);
    setReloadShifts(Math.random() * 40);
    setReloadUsers(Math.random() * 40);
    setReloadProducts(Math.random() * 40);
    Form.resetForm();
  };

  useEffect(() => {
    setProductListMemo(productList);
  }, [productList]);

  useEffect(() => {
    if (loadingRequest) {
      setLoadingForm(false);
    }
  }, [loadingRequest]);

  useEffect(() => {
    if (isSuccessID) {
      setReloadDay(Math.random() * 40);
    }
  }, [isSuccessID]);

  useEffect(() => {
    if (day) {
      const { dia, fecha_str } = day;
      if (dia) {
        setTextDay(fecha_str);
        setCurrentDayId(dia.id || 0);
        setExistDay(true);
        Form.setFieldValue('id_dia', dia.id || 0);
      } else {
        Form.setFieldValue('id_dia', 0);
        setTextDay('');
        setCurrentDayId(0);
        setExistDay(false);
      }
    }
  }, [day]);

  useEffect(() => {
    if (isSuccess) {
      cleanForm();
      setReloadBoxes(Math.random() * 40);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);
  return (
    <>
      <div className="d-flex flex-column flex-xl-row">
        <div className="flex-lg-row-fluid">
          <div className="card mb-6 mb-xl-9">
            <div className="card-header border-0">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bold text-gray-800">
                  Iniciar día administrativo
                </span>
              </h3>
            </div>
            <div className="card-body">
              <div className="d-flex">
                <button
                  type="button"
                  title="Iniciar día"
                  onClick={initDay}
                  className="btn btn-success w-200px"
                  disabled={loadingID || existDay}
                >
                  {!loadingID && (
                    <span className="indicator-label">Iniciar día</span>
                  )}
                  {loadingID && (
                    <span
                      className="indicator-progress"
                      style={{ display: 'block' }}
                    >
                      Espere por favor
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  )}
                </button>
                <div className="d-flex justify-content-center flex-column ms-10">
                  <span className="fs-4 text-gray-700">{textDay}</span>
                </div>
              </div>
            </div>
          </div>

          {existDay && (
            <>
              <div className="card mb-6 mb-xl-9">
                <div className="card-header border-0">
                  <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bold text-gray-800">
                      Iniciar caja y turno
                    </span>
                  </h3>
                </div>
                <div className="card-body">
                  <form
                    id="formBox"
                    className="form"
                    action="#"
                    noValidate
                    onSubmit={Form.handleSubmit}
                  >
                    <div className="row">
                      <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                        <div className="w-100 pe-5">
                          <div className="w-100 d-flex">
                            <label className="form-label w-auto">
                              Fecha administrativa:{' '}
                            </label>
                            <div className="w-auto">
                              {Form.touched.id_dia && Form.errors.id_dia && (
                                <div className="fv-plugins-message-container text-danger w-auto ms-5">
                                  <span role="alert">{Form.errors.id_dia}</span>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="w-100 d-flex">
                            <span className="mt-3 fs-6 text-gray-700">
                              {textDay}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                        <div className="w-100 pe-5">
                          <div className="w-100 d-flex">
                            <label className="required form-label w-auto">
                              Caja:{' '}
                            </label>
                            <div className="w-auto">
                              {Form.touched.id_punto_venta &&
                                Form.errors.id_punto_venta && (
                                  <div className="fv-plugins-message-container text-danger w-auto ms-5">
                                    <span role="alert">
                                      {Form.errors.id_punto_venta}
                                    </span>
                                  </div>
                                )}
                            </div>
                          </div>

                          <Select
                            options={POS}
                            value={selectedOptionPOS}
                            styles={styleSelect}
                            placeholder={'Seleccione'}
                            isSearchable={true}
                            noOptionsMessage={() => {
                              return (
                                <span>{'No se encontraron registros'}</span>
                              );
                            }}
                            onChange={changePOS}
                            isLoading={loadingPOS}
                            isClearable
                            backspaceRemovesValue
                          />
                        </div>
                      </div>

                      <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                        <div className="w-100 pe-5">
                          <div className="w-100 d-flex">
                            <label className="required form-label w-auto">
                              Turno:{' '}
                            </label>
                            <div className="w-auto">
                              {Form.touched.id_turno && Form.errors.id_turno && (
                                <div className="fv-plugins-message-container text-danger w-auto ms-5">
                                  <span role="alert">
                                    {Form.errors.id_turno}
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>
                          <Select
                            options={shifts}
                            value={selectedOptionShifts}
                            styles={styleSelect}
                            placeholder={'Seleccione'}
                            isSearchable={true}
                            noOptionsMessage={() => {
                              return (
                                <span>{'No se encontraron registros'}</span>
                              );
                            }}
                            onChange={changeShifts}
                            isLoading={loadingSHI}
                            isClearable
                            backspaceRemovesValue
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                        <div className="w-100 pe-5">
                          <div className="w-100 d-flex">
                            <label className="required form-label w-auto">
                              Cajero:{' '}
                            </label>
                            <div className="w-auto">
                              {Form.touched.id_usuario_encargado &&
                                Form.errors.id_usuario_encargado && (
                                  <div className="fv-plugins-message-container text-danger w-auto ms-5">
                                    <span role="alert">
                                      {Form.errors.id_usuario_encargado}
                                    </span>
                                  </div>
                                )}
                            </div>
                          </div>
                          <Select
                            options={users}
                            value={selectedOptionUser}
                            styles={styleSelect}
                            placeholder={'Seleccione'}
                            isSearchable={true}
                            noOptionsMessage={() => {
                              return (
                                <span>{'No se encontraron registros'}</span>
                              );
                            }}
                            onChange={changeUsers}
                            isLoading={loadingUsers}
                            isClearable
                            backspaceRemovesValue
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mt-10">
                      <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-3"></div>
                      <div className="col-sm-8 col-md-8 col-lg-8 col-xl-8 col-xxl-6">
                        <div className="table-responsive">
                          <table className="table align-middle gs-0 gy-4 my-0">
                            <thead></thead>
                            <tbody>
                              <tr>
                                <td>
                                  <span className="required fw-bold fs-5 text-gray-700 w-600px">
                                    Efectivo Entregado:
                                  </span>
                                  {Form.touched.efectivo_apertura &&
                                    Form.errors.efectivo_apertura && (
                                      <div className="fv-plugins-message-container text-danger w-auto">
                                        <span role="alert">
                                          {Form.errors.efectivo_apertura}
                                        </span>
                                      </div>
                                    )}
                                </td>
                                <td className="">
                                  <NumericFormat
                                    type="text"
                                    value={Form.values.efectivo_apertura}
                                    autoComplete="off"
                                    className="form-control"
                                    decimalScale={2}
                                    decimalSeparator="."
                                    fixedDecimalScale
                                    placeholder={'$ 0.00'}
                                    prefix={'$ '}
                                    allowNegative={false}
                                    thousandSeparator={','}
                                    onValueChange={changeCash}
                                  />
                                </td>
                              </tr>
                              {productListMemo.length > 0 ? (
                                productListMemo.map((row, index) => (
                                  <tr key={index}>
                                    <td>
                                      <span className="fw-bold fs-5 text-gray-700 w-600px">
                                        {row.nombre}:
                                      </span>
                                    </td>

                                    <td className="">
                                      <NumericFormat
                                        className="form-control w-100"
                                        onKeyDown={(e) => {
                                          if (e.key === 'Enter') {
                                            e.preventDefault();
                                            e.stopPropagation();
                                          }
                                        }}
                                        value={row.cantidad || 0}
                                        onBlur={(e) => {
                                          const value = e.target.value;
                                          if (!value) {
                                            e.target.value = '0';
                                          }
                                        }}
                                        thousandSeparator={false}
                                        allowNegative={false}
                                        decimalScale={0}
                                        suffix=""
                                        allowLeadingZeros={false}
                                        onValueChange={(values) => {
                                          const { floatValue } = values;
                                          if (floatValue) {
                                            // if (floatValue > 0 && floatValue <= 999) {
                                            changeAmount(floatValue, row.id);
                                            // }
                                          } else {
                                            changeAmount(0, row.id);
                                          }
                                        }}
                                      />
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td
                                    colSpan={2}
                                    className="text-center text-muted"
                                  >
                                    Sin productos
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3"></div>
                    </div>
                    <div className="row">
                      <div className="d-flex justify-content-end mt-10">
                        <button
                          type="submit"
                          className="btn btn-primary"
                          data-kt-users-modal-action="submit"
                          disabled={loadingForm}
                        >
                          {!loadingForm && (
                            <span className="indicator-label">
                              Inicializar caja
                            </span>
                          )}
                          {loadingForm && (
                            <span
                              className="indicator-progress"
                              style={{ display: 'block' }}
                            >
                              Espere por favor...
                              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                          )}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="card mb-6 mb-xl-9">
                <div className="card-header border-0">
                  <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bold text-gray-800">
                      Turnos de cajas iniciados
                    </span>
                  </h3>
                </div>
                <div className="card-body">
                  <TableList data={boxList} columns={columns}></TableList>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <DetailModal
        show={showModal}
        id_row={row_id}
        handleClose={(updateGrid: boolean) => {
          if (updateGrid) {
          } else {
            setShowModal(!showModal);
          }
        }}
      ></DetailModal>
    </>
  );
};

export { View };
