import React, { useState, useEffect, useRef } from "react";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import Select from "react-select";
import { KTSVG, SimpleAlert } from "../../../../../rbts/helpers";
import { styleSelect } from "../../../../../rbts/assets/ts/_utils";
import { PatternFormat } from "react-number-format";
// import { useSelectGeneric } from "../../catalogos/generico/hooks/genericoHook";
// import { useGetNoteByID, useEditNote } from '../hooks/NoteHook';

type Props = {
  id: string;
};

const noteSchema = Yup.object().shape({
  nombre: Yup.string().required("Nombre requerido."),
  descripcion: Yup.string().required("Descripción requerido."),
});

const SupplierForm: React.FC<Props> = ({ id }) => {
  const [reloadInfoCurrentTask, setReloadInfoCurrentTask] = useState(
    Math.random() * 40
  );
  //   const { noteInit } = useGetNoteByID(id, reloadInfoCurrentTask);
  //   const { editNote, loadingEditNote } = useEditNote();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const SupplierFormik = useFormik({
    initialValues: {
      //   ...noteInit,
    },
    validationSchema: noteSchema,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      setLoading(true);
      //   editNote(values);
    },
  });

  //   useEffect(() => {
  //     if (loadingEditNote) {
  //       history.goBack();
  //       SimpleAlert('Se actualizó la nota exitosamente!', 3000, 'success');
  //       setLoading(false);
  //     }
  //   }, [loadingEditNote]);

  // const userSelected = (selectedOption: any) => {
  //   const { value } = selectedOption;
  //   taskForm.setFieldValue('user_asignado_id', value);
  // };

  return (
    <div className="card mb-xl-5 w-100">
      <div className="card-body">
        <form
          id="kt_account_form"
          className="form"
          action="#"
          noValidate
          onSubmit={SupplierFormik.handleSubmit}
        >
          <div className="row">
            <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
              <div className="w-100 pe-5">
                <div className="w-100 d-flex mt-5">
                  <label className="form-label w-auto required">Nombre</label>
                </div>
                <Select
                  // options={POS}
                  // value={selectedOptionPOS}
                  styles={styleSelect}
                  placeholder={"Seleccione"}
                  isSearchable={true}
                  noOptionsMessage={() => {
                    return <span>{"No se encontraron registros"}</span>;
                  }}
                  // onChange={changePOS}
                  // isLoading={loadingPOS}
                  isClearable
                  backspaceRemovesValue
                />
              </div>
            </div>
            <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
              <div className="w-100 pe-5">
                <div className="w-100 d-flex mt-5">
                  <label className="form-label w-auto required">
                    Nombre del puesto
                  </label>
                </div>
                <input type="text" className="form-control" />
              </div>
            </div>
            <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
              <div className="w-100 pe-5">
                <div className="w-100 d-flex mt-5">
                  <label className="form-label w-auto">Teléfono</label>
                </div>
                <PatternFormat
                  format="+52 ### #######"
                  allowEmptyFormatting
                  className="form-control"
                  onChange={(event) => {
                    const numeroSinMascara = event.target.value.replace(
                      /\D/g,
                      ""
                    );
                  }}
                />
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-end mt-10">
            <button
              type="reset"
              className="btn btn-light me-3"
              data-kt-users-modal-action="cancel"
              onClick={() => {
                history.goBack();
              }}
            >
              Cancelar
            </button>
            <button
              type="submit"
              className="btn btn-primary"
              data-kt-users-modal-action="submit"
              disabled={loading}
            >
              {!loading && <span className="indicator-label">Guardar</span>}
              {loading && (
                <span
                  className="indicator-progress"
                  style={{ display: "block" }}
                >
                  Espere por favor...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
export { SupplierForm };
