import React, { useState, useEffect, useRef } from "react";
import { formatPrice } from "../../../../rbts/helpers";
import CountUp from "react-countup";
import { DetalleConciliacion } from "../models/ConciliationModel";

type Props = {
  data: any[];
};

const ConciliationDetailList: React.FC<Props> = ({ data }) => {
  const [detail, setDetail] = useState<any>(data);
  const [totalAmount, setTotalAmount] = useState<number>(0);
  const [totalElectronicBalance, setTotalElectronicBalance] =
    useState<number>(0);
  const [totalBank, setTotalBank] = useState<number>(0);
  const [totalsupplier, setTotalsupplier] = useState<number>(0);
  const [totalRawMaterial, setTotalRawMaterial] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [splitBank, setSplitBank] = useState<number>(0);
  const [splitSailPoint, setSplitSailPoint] = useState<number>(0);

  useEffect(() => {
    if (data) {
      const {
        total_cantidad,
        total_saldo_electronico,
        total_banco,
        total_punto_venta,
        total_materia_prima,
        total_a_pagar,
        split_banco,
        split_punto_venta,
      }: any = data;
      setTotalAmount(total_cantidad);
      setTotalElectronicBalance(total_saldo_electronico);
      setTotalBank(total_banco);
      setTotalsupplier(total_punto_venta);
      setTotalRawMaterial(total_materia_prima);
      setTotal(total_a_pagar);
      setSplitBank(split_banco ? split_banco : 0);
      setSplitSailPoint(split_punto_venta ? split_punto_venta : 0);
      setDetail(data);
    } else {
    }
  }, [data]);

  return (
    <>
      <div className="table-responsive min-h-300px me-n5 pe-5 scroll-y w-100 mt-5">
        <table className="table table-border table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 me-n5 pe-5 w-100">
          <thead>
            <tr className="text-gray-800 fs-7">
              <th className="w-300px"></th>
              <th className="w-250px"></th>
              <th className="w-250px"></th>
              <th className="w-250px"></th>
              <th className="w-250px text-primary"></th>
              <th className="w-250px text-primary">
                {" "}
                Split {`${splitSailPoint}/${splitBank}`}
              </th>
              <th className="w-500px text-primary">Deuda materia prima </th>
            </tr>
            <tr className="text-gray-800 fs-7">
              <th className="w-300px">Productos</th>
              <th className="w-250px">Precio</th>
              <th className="w-100px">Cantidad</th>
              <th className="w-250px">Saldo electrónico</th>
              <th className="w-250px">Banco</th>
              <th className="w-250px">Proveedor</th>
              <th className="w-250px">Materia prima</th>
              <th className="w-250px">Total</th>
            </tr>
          </thead>
          <tbody>
            {detail && detail?.detalle ? (
              detail.detalle.map((item: DetalleConciliacion, index: number) => (
                <tr key={index}>
                  <td>{item.producto_nombre}</td>
                  <td>
                    {formatPrice(
                      item.precio ? item.precio : 0,
                      "es-MX",
                      "MXN",
                      2,
                      2
                    )}
                  </td>
                  <td>{item.cantidad}</td>
                  <td>
                    {formatPrice(
                      item.saldo_electronico ? item.saldo_electronico : 0,
                      "es-MX",
                      "MXN",
                      2,
                      2
                    )}
                  </td>
                  <td>
                    {formatPrice(
                      item.monto_banco ? item.monto_banco : 0,
                      "es-MX",
                      "MXN",
                      2,
                      2
                    )}
                  </td>
                  <td>
                    {formatPrice(
                      item.monto_punto_venta ? item.monto_punto_venta : 0,
                      "es-MX",
                      "MXN",
                      2,
                      2
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={9} className="text-center text-muted">
                  Sin registros
                </td>
              </tr>
            )}
          </tbody>
          <tfoot>
            <tr>
              <td></td>
              <td className="text-bold fs-6">
                <span>Total</span>
              </td>
              <td>{totalAmount}</td>
              <td className="text-success">
                <CountUp
                  end={totalElectronicBalance}
                  duration={1}
                  prefix="$"
                  decimals={2}
                />
              </td>
              <td className="text-success">
                <CountUp end={totalBank} duration={1} prefix="$" decimals={2} />
              </td>
              <td className="text-success">
                <CountUp
                  end={totalsupplier}
                  duration={1}
                  prefix="$"
                  decimals={2}
                />
              </td>
              <td className="text-primary">
                <CountUp
                  end={totalRawMaterial}
                  duration={1}
                  prefix="$"
                  decimals={2}
                />
              </td>
              <td className="text-primary">
                <CountUp end={total} duration={1} prefix="$" decimals={2} />
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </>
  );
};

export { ConciliationDetailList };
