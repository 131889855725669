import React, { useState, useEffect } from "react";
import Select from "react-select";
import { styleSelect } from "../../../../../rbts/assets/ts/_utils";
import { useGetAllDaysClosed } from "../hooks";
import { BoxCutDetail } from "../sections/BoxCutDetail";
import { ConsiliationSupplierDetail } from "../sections/ConsiliationSupplierDetail";
import { DonationDetail } from "../sections/DonationDetail";
import { CourtesiesDetail } from "../sections/CourtesiesDetail";
import { TotalSections } from "../sections/TotalSections";

type Props = {};

const View: React.FC<Props> = ({}) => {
  const [currentDay, setCurrentDay] = useState<any | []>(null);
  const { catalog: daysClosed, loadingRequest } = useGetAllDaysClosed();

  const onChangeDaysSelect = (selectoption: any) => {
    if (selectoption) {
      setCurrentDay(selectoption);
    } else {
      setCurrentDay(null);
    }
  };

  return (
    <>
      <div className="d-flex flex-column flex-xl-row">
        <div className="flex-lg-row-fluid">
          <div className="card mb-6 mb-xl-9">
            <div className="card-header border-0">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bold text-gray-800">Fechas</span>
              </h3>
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bold text-gray-800">
                  Fecha administrativa:{" "}
                  {currentDay ? currentDay?.label : "seleccione una fecha"}
                </span>
              </h3>
            </div>
            <div className="card-body">
              <div className=" col-md-4">
                <label className="mb-2">Fecha administrativa</label>
                <Select
                  options={daysClosed}
                  value={currentDay}
                  styles={styleSelect}
                  placeholder={"Seleccione"}
                  isSearchable={true}
                  noOptionsMessage={() => {
                    return <span>{"No se encontraron registros"}</span>;
                  }}
                  onChange={onChangeDaysSelect}
                  isLoading={loadingRequest}
                  isClearable
                  backspaceRemovesValue
                />
              </div>
            </div>
          </div>
          <div className={`card card-xxl-stretch mb-5 mb-xl-12`}>
            <div className="card-header border-0">
              <h5 className="card-title align-items-start flex-column">
                <span className="card-label fw-bold text-gray-500">
                  Cortes de caja
                </span>
              </h5>
            </div>
            <div className="card-body">
              <BoxCutDetail></BoxCutDetail>
            </div>
          </div>
          <div className={`card card-xxl-stretch mb-5 mb-xl-12`}>
            <div className="card-header border-0">
              <h5 className="card-title align-items-start flex-column">
                <span className="card-label fw-bold text-gray-500">
                  Conciliaciones con proveedores
                </span>
              </h5>
            </div>
            <div className="card-body">
              <ConsiliationSupplierDetail></ConsiliationSupplierDetail>
            </div>
          </div>
          <div className={`card card-xxl-stretch mb-5 mb-xl-12`}>
            <div className="card-header border-0">
              <h5 className="card-title align-items-start flex-column">
                <span className="card-label fw-bold text-gray-500">
                  Donativos
                </span>
              </h5>
            </div>
            <div className="card-body">
              <DonationDetail></DonationDetail>
            </div>
          </div>
          <div className={`card card-xxl-stretch mb-5 mb-xl-12`}>
            <div className="card-header border-0">
              <h5 className="card-title align-items-start flex-column">
                <span className="card-label fw-bold text-gray-500">
                  Cortesias
                </span>
              </h5>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-6 ">
                  <CourtesiesDetail></CourtesiesDetail>
                </div>
                <div className="col-md-6">
                  <TotalSections></TotalSections>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { View };
