import React from "react";
import { LoadingGrid, TableList, formatPrice } from "../../../../rbts/helpers";
import { useGetAllShiftSupplier } from "../hooks/StartShiftHook";

type Props = {
  reloadGrid: number;
};

const ShiftSupplierGrid: React.FC<Props> = ({ reloadGrid }) => {
  const { loadingRequest, shiftList } = useGetAllShiftSupplier(reloadGrid);
  const columns: any = [
    {
      id: "folio",
      name: (
        <div className="fw-bolder text-muted me-5">Fecha administrativa</div>
      ),
      selector: (row: any) => row.dia_fecha,
      cell: (row: any) => (
        <>
          <span className="text-primary">
            {row.dia_fecha ? row.dia_fecha : "No registrado"}
          </span>
        </>
      ),
      sortable: false,
      width: "20%",
    },
    {
      id: "punto_venta",
      name: <div className="fw-bolder text-muted me-5">Nombre del puesto</div>,
      cell: (row: any) => (
        <>
          <span>
            {row.nombre_punto_venta ? row.nombre_punto_venta : "No registrado"}
          </span>
        </>
      ),
      sortable: false,
      width: "25%",
    },
    {
      id: "proveedor",
      name: <div className="fw-bolder text-muted me-5">Turno</div>,
      cell: (row: any) => (
        <>
          <span
            className={` badge ${
              row.estatus_punto_venta == "Abierto"
                ? "badge-success"
                : "badge-danger"
            }`}
          >
            {row.estatus_punto_venta}
          </span>
        </>
      ),
      sortable: false,
      width: "15%",
    },
    {
      id: "proveedor",
      name: <div className="fw-bolder text-muted me-5">Abierto por:</div>,
      cell: (row: any) => (
        <>
          <span className="text-muted">
            {row.abierto_por ? row.abierto_por : "N/A"}
          </span>
        </>
      ),
      sortable: false,
      width: "20%",
    },
    {
      id: "proveedor",
      name: <div className="fw-bolder text-muted me-5">Cerrado por:</div>,
      cell: (row: any) => (
        <>
          <span className="text-muted">
            {row.cerrado_por ? row.cerrado_por : ""}
          </span>
        </>
      ),
      sortable: false,
      width: "20%",
    },
    {
      id: "proveedor",
      name: <div className="fw-bolder text-muted me-5">Conciliado</div>,
      cell: (row: any) => (
        <>
          <span
            className={` badge ${
              row.isConciliado ? "badge-success" : "text-primary"
            }`}
          >
            {row.isConciliado ? "conciliado" : "N/A"}
          </span>
        </>
      ),
      sortable: false,
      width: "20%",
    },
  ];
  return (
    <>
      <div className="row gy-5 g-xl-8">
        <div className="col-xl-12">
          {loadingRequest ? (
            <LoadingGrid text="Cargando..."></LoadingGrid>
          ) : (
            <TableList data={shiftList} columns={columns}></TableList>
          )}
        </div>
      </div>
    </>
  );
};

export { ShiftSupplierGrid };
