import React, { useState, useEffect } from "react";
import Select from "react-select";
import { styleSelect } from "../../../../../rbts/assets/ts/_utils";

import {
  SimpleAlert,
  TableList,
  formatDateString,
  formatPrice,
} from "../../../../../rbts/helpers";
import { useGetAllDaysSelect } from "../hooks";

type Props = {};

const BoxCutDetail: React.FC<Props> = ({}) => {
  const { catalog: day } = useGetAllDaysSelect();
  const [strDate, setStrDate] = useState<string>("");
  const [currentDay, setCurrentDay] = useState<number>(0);
  const detalleInicial: any = [];

  return (
    <>
      <div className="d-flex flex-column flex-xl-row">
        <div className="flex-lg-row-fluid">
          <div className="row">
            <div className="col-md-3  border-dashed">
              <div className="card card-flush " style={{ height: "100%" }}>
                <div className="card-header pt-5">
                  <h3 className="card-title text-primary fw-bold">
                    Sección inicial
                  </h3>
                </div>

                <div className="card-body pt-5">
                  <div className="d-flex flex-stack mb-3">
                    <label className="form-label w-auto">
                      Efectivo inicial:
                    </label>

                    <div className="text-end fw-bold fs-6 text-gray-600">0</div>
                  </div>

                  {detalleInicial.length > 0 ? (
                    detalleInicial.map((row: any, index: any) => (
                      <>
                        <div className="d-flex flex-stack mb-3">
                          <label className="form-label w-auto">
                            {row.producto.nombre || "Sin nombre"}
                            {":"}
                          </label>

                          <div className="text-end fw-bold fs-6 text-gray-600">
                            {row.cantidad || 0}
                          </div>
                        </div>
                      </>
                    ))
                  ) : (
                    <div className="d-flex flex-stack mb-3">
                      <label className="form-label w-auto">Sin registros</label>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="col-md-3  border-dashed">
              <div className="card card-flush " style={{ height: "100%" }}>
                <div className="card-header pt-5">
                  <h3 className="card-title text-primary fw-bold">
                    Sección ventas
                  </h3>
                </div>

                <div className="card-body pt-5">
                  <div className="row mb-2">
                    <div className="col-sm-8 mb-1">Brazaletes</div>
                    <div className="col-sm-4">e</div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-sm-8 mb-1">Boletos</div>
                    <div className="col-sm-4">e</div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-sm-8 mb-1">Ticketcash</div>
                    <div className="col-sm-4">e</div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-sm-8 mb-1">Ventas efectivo</div>
                    <div className="col-sm-4">e</div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-sm-8 mb-1">Ventas con tarjeta</div>
                    <div className="col-sm-4">e</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6  border-dashed">
              <div className="card card-flush " style={{ height: "100%" }}>
                <div className="card-header pt-5">
                  <h3 className="card-title text-primary fw-bold">
                    Sección final
                  </h3>
                </div>

                <div className="card-body pt-5">
                  <div className="row mb-2">
                    <div className="col-sm-3"></div>
                    <div className="col-sm-3">Solicitado</div>
                    <div className="col-sm-3">Entregado</div>
                    <div className="col-sm-3">Diferencias</div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-sm-3 mb-1">Brazaletes</div>
                    <div className="col-sm-3">e</div>
                    <div className="col-sm-3">e</div>
                    <div className="col-sm-3">e</div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-sm-3 mb-1">Boletos</div>
                    <div className="col-sm-3">e</div>
                    <div className="col-sm-3">e</div>
                    <div className="col-sm-3">e</div>
                  </div>
                  <div className="row mb-2">
                    <div
                      className="col-sm-3 mb-1 large-text"
                      title="Ventas efectivo"
                    >
                      Ventas efectivo{" "}
                    </div>
                    <div className="col-sm-3">e</div>
                    <div className="col-sm-3">e</div>
                    <div className="col-sm-3">e</div>
                  </div>
                  <div className="row mb-2">
                    <div
                      className="col-sm-3 mb-1 large-text"
                      title="Ventas con tarjeta"
                    >
                      Ventas con tarjeta{" "}
                    </div>
                    <div className="col-sm-3">e</div>
                    <div className="col-sm-3">e</div>
                    <div className="col-sm-3">e</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { BoxCutDetail };
