import axios from "axios";
import { DonationsModel } from "../../pages/donations/models/DonationsModel";
const API_URL = process.env.REACT_APP_API;

export async function getAllDonations() {
  const response = await axios.get(`${API_URL}donativos`);
  const data = response.data.doc.data;
  return data;
}

export async function createNewDonations(body: DonationsModel) {
  const response = await axios.post(`${API_URL}donativos`, body);
  const data = response.data.doc.data;
  return data;
}
