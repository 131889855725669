import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { getAllDaysSelect } from "../../../../service/box_delivery";
import { getAllDays } from "../../../../service/day_cut/DayCut";

export const useGetAllDaysSelect = (reload?: number) => {
  const [catalog, setCatalog] = useState<any>();
  const [loadingRequest, setLoadingRequest] = useState(true);

  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      const data = await getAllDaysSelect()
        .then()
        .catch((error: any) => {
          history.push("/error/500");
        });
      if (data) {
        setCatalog(data);
        setLoadingRequest(false);
      }
    };
    fetchPost();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  return { catalog, loadingRequest };
};

export const useGetAllDaysClosed = (reload?: number) => {
  const [catalog, setCatalog] = useState<any>();
  const [loadingRequest, setLoadingRequest] = useState(true);

  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      const data = await getAllDays()
        .then()
        .catch((error: any) => {
          history.push("/error/500");
        });
      if (data) {
        setCatalog(data);
        setLoadingRequest(false);
      }
    };
    fetchPost();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  return { catalog, loadingRequest };
};
