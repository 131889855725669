/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react';
import { useIntl } from 'react-intl';
import { PageTitle } from '../../../rbts/layout/core';

const DashboardWrapper: FC = () => {
  const intl = useIntl();
  return (
    <>
      <PageTitle breadcrumbs={[]}>Inicio</PageTitle>

      <div className='row min-h-600px border d-flex justify-content-center align-items-center text-center'>
        <h1>BIENVENIDOS</h1>
      </div>
    </>
  );
};

export { DashboardWrapper };
