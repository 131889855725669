import axios from "axios";
import { SelectFormat } from "../../../rbts/helpers/models/GenericModel";
const API_URL = process.env.REACT_APP_API;

export async function getAllDays() {
  const response = await axios.get(`${API_URL}/diaventa/dias/cerrados`);
  const data = response.data.doc.data;
  let select: SelectFormat[] = [];
  data.forEach((item: any) => {
    const selectCustom = {
      id: Number(item.id),
      value: item.id,
      label: `${item.fecha}`,
      data: item,
    };
    select.push(selectCustom);
  });
  return select;
}
