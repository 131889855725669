import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { ConciliationDetailList } from "./ConcilationDetailGrid";
import {
  useExecuteConciliation,
  useGetConciliationBySupplier,
} from "../hooks/ConsilationHook";
import Swal from "sweetalert2";

type Props = {
  supplierId: number;
  dayId: number;
  handleClose: () => void;
};

interface InitialValues {
  id_punto_venta: number;
  id_dia: number;
}
const ConciliationForm: React.FC<Props> = ({
  supplierId,
  dayId,
  handleClose,
}) => {
  const { conciliationSupplier, error } =
    useGetConciliationBySupplier(supplierId);

  const [
    isDisabledBtnConciliationConfirm,
    setIsDisabledBtnConciliationConfirm,
  ] = useState<boolean>(false);

  const [loading, setLoading] = useState(false);
  const { generateConciliation } = useExecuteConciliation();

  const initValues: InitialValues = {
    id_punto_venta: supplierId,
    id_dia: dayId,
  };

  const ConciliationForm = useFormik({
    initialValues: initValues,
    enableReinitialize: true,
    onSubmit: (values) => {
      setLoading(true);

      try {
        Swal.fire({
          title: "¿Estas seguro?",
          html: `
          <div className="text-gray-600">
            Se va a generar una conciliación, una vez
            generada ya no se podrá modificar, verifica que todo este correcto. 
          </div>
          `,
          showCancelButton: true,
          padding: "2em",
          cancelButtonText: "Cancelar",
          confirmButtonText: "Aceptar",
          reverseButtons: true,
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-light me-3",
          },
        }).then(async (result) => {
          if (result.isConfirmed) {
            await generateConciliation(values);
            handleClose();
            setLoading(false);
          } else {
            setLoading(false);
          }
        });
      } catch (error) {
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    if (error) {
      setIsDisabledBtnConciliationConfirm(true);
    } else {
      setIsDisabledBtnConciliationConfirm(false);
    }
  }, [error]);

  // useEffect(() => {
  //   if (conciliationSupplier.length) {
  //     setIsDisabledBtnConciliationConfirm(false);
  //   } else {
  //     setIsDisabledBtnConciliationConfirm(true);
  //   }
  // }, [conciliationSupplier]);

  return (
    <>
      <form
        id="kt_account_form"
        className="form"
        action="#"
        noValidate
        onSubmit={ConciliationForm.handleSubmit}
      >
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
            <ConciliationDetailList data={conciliationSupplier} />
          </div>
        </div>

        <div className="d-flex justify-content-end mt-10">
          <button
            type="submit"
            className="btn btn-primary ms-4"
            data-kt-users-modal-action="submit"
            disabled={loading || isDisabledBtnConciliationConfirm}
            // disabled={loading || isDisabledBtnConciliationConfirm}
          >
            {!loading && <span className="indicator-label">Conciliar</span>}
            {loading && (
              <span className="indicator-progress" style={{ display: "block" }}>
                Espere por favor...
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </>
  );
};
export { ConciliationForm };
