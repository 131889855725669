



import { useState, useEffect } from 'react';
import { SelectFormat } from '../../../../../rbts/helpers/models/GenericModel'; 
import { useHistory } from 'react-router-dom';
import { createBoxCut, createBoxDelivery, createFinishDayDelivery, createInitDayDelivery, getAllCashierUserSelect, getAllDaysSelect, getAllPOSSelect, getAllProducts, getAllShiftsSelect, getBoxesStarted, getCutStarted, getDetailFinish, getDetailInit, getInfoCut, getInfoPerUser } from '../../../../service/box_delivery';
import { ProductModel } from '../models/ProductModel';
import { SimpleAlert } from '../../../../../rbts/helpers';
import { EntregaCaja } from '../../box_cut/models/CutModel';
import { CutInfo } from '../../box_cut/models/CutInfoModel';

export const useGetAllShiftsSelect = (reload: number) => {
  const [catalog, setCatalog] = useState<SelectFormat[]>([]);
  const [loadingRequest, setLoadingRequest] = useState(true);

  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      const data = await getAllShiftsSelect()
        .then()
        .catch((error: any) => {
          history.push('/error/500');
        });
      if (data) {
        setCatalog(data);
        setLoadingRequest(false);
      }
    };
    fetchPost();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  return { catalog, loadingRequest };
};

export const useGetAllPOSSelect = (reload: number) => {
  const [catalog, setCatalog] = useState<SelectFormat[]>([]);
  const [loadingRequest, setLoadingRequest] = useState(true);

  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      const data = await getAllPOSSelect()
        .then()
        .catch((error: any) => {
          history.push('/error/500');
        });
      if (data) {
        setCatalog(data);
        setLoadingRequest(false);
      }
    };
    fetchPost();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  return { catalog, loadingRequest };
};

export const useGetAllDaysSelect = (reload: number) => {
  const [catalog, setCatalog] = useState<any>();
  const [loadingRequest, setLoadingRequest] = useState(true);

  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      const data = await getAllDaysSelect()
        .then()
        .catch((error: any) => {
          history.push('/error/500');
        });
      if (data) {
        setCatalog(data);
        setLoadingRequest(false);
      }
    };
    fetchPost();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  return { catalog, loadingRequest };
};
// export const useGetValidateCloseDay = () => {
//   const [validateCloseDay, setValidateCloseDay] = useState<boolean>(false);
//   const [loadingRequest, setLoadingRequest] = useState(true);

//   const history = useHistory();
//   useEffect(() => {
//     const fetchPost = async () => {
//       const data = await getValidateCloseDay()
//         .then()
//         .catch((error: any) => {
//           history.push('/error/500');
//         });
//       if (data) {
//         setValidateCloseDay(data);
//         setLoadingRequest(false);
//       }else{
//         setValidateCloseDay(false);
//         setLoadingRequest(false);
//       }
//     };
//     fetchPost();
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, []);

//   return { validateCloseDay, loadingRequest };
// };

export const useGetAllCashierUserSelect = (reload: number) => {
  const [catalog, setCatalog] = useState<SelectFormat[]>([]);
  const [loadingRequest, setLoadingRequest] = useState(true);

  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      const data = await getAllCashierUserSelect()
        .then()
        .catch((error: any) => {
          history.push('/error/500');
        });
      if (data) {
        setCatalog(data);
        setLoadingRequest(false);
      }
    };
    fetchPost();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  return { catalog, loadingRequest };
};

export const useGetAllProducts = (reload: number) => {
  const [productList, setProductList] = useState<ProductModel[]>([]);
  const [loadingRequest, setLoadingRequest] = useState<boolean>(true);
  const history = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      setLoadingRequest(true);
      try {
        const products = await getAllProducts();
        setProductList(products);
        setLoadingRequest(false);
      } catch (error) {
        history.push('/error/500');
      }
    };
    fetchData();
  }, [reload, history]);

  return { productList, loadingRequest };
};

export const useGetBoxesStarted = (reload: number, id_dia: number) => {
  const [boxList, setBoxList] = useState<any[]>([]);
  const [loadingRequest, setLoadingRequest] = useState<boolean>(true);
  const history = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      setLoadingRequest(true);
      try {
        const boxes = await getBoxesStarted(id_dia);
        setBoxList(boxes);
        setLoadingRequest(false);
      } catch (error) {
        history.push('/error/500');
      }
    };
    if(id_dia > 0){
      fetchData();
    }
  }, [reload, id_dia]);

  return { boxList, loadingRequest };
};

export const useBoxDelivery = () => {

  const [loading, setLoading] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const history = useHistory();
  const boxDelivery = async (box: any) => {
    setLoading(false);
    setIsSuccess(false);
    try {
      await createBoxDelivery(box);
      SimpleAlert('Se entregó la caja exitosamente!', 3000, 'success');
      setLoading(true);
      setIsSuccess(true);
    } catch (error: any) {
      if (error && error.response) {
        const { message } = error.response.data;
        SimpleAlert(message, 3000, 'error');
        setLoading(true);
      } else {
        history.push('/error/500');
      }
    }
  };
  return { boxDelivery, loading, isSuccess  };
};

export const useInitDayDelivery = () => {

  const [loading, setLoading] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const history = useHistory();
  const initDayDelivery = async () => {
    setLoading(true);
    setIsSuccess(false);
    try {
      await createInitDayDelivery();
      SimpleAlert('Se inició el día exitosamente!', 3000, 'success');
      setLoading(false);
      setIsSuccess(true);
    } catch (error: any) {
      if (error && error.response) {
        const { message } = error.response.data;
        SimpleAlert(message, 3000, 'error');
        setLoading(false);
      } else {
        history.push('/error/500');
      }
    }
  };
  return { initDayDelivery, loading, isSuccess, setLoading  };
};

export const useGetInfoCut = (reload: number, filter: any) => {
  const [cut, setCut] = useState<CutInfo>();
  const [loadingRequest, setLoadingRequest] = useState(false);

  const history = useHistory();
  useEffect(() => {
    setCut({
      id: 0,
      id_usuario_encargado: 0,
      punto_venta: null,
      turno: null,
      transaccion_cuenta: null,
      transaccion_caja: null,
      corte_dia: false,
      inventario_ventas: []
    });
    const fetchPost = async () => {
      setLoadingRequest(true);
      const data = await getInfoCut(filter)
        .then()
        .catch((error: any) => {
          // history.push('/error/500');
        });
      if (data) {
        setCut(data);
        setLoadingRequest(false);
      }else{
        setCut({
          id: 0,
          id_usuario_encargado: 0,
          punto_venta: null,
          turno: null,
          transaccion_cuenta: null,
          transaccion_caja: null,
          corte_dia: false,
          inventario_ventas: []
        });
        SimpleAlert("No hay turnos inicializados para ese cajero.", 3000, 'error');
        setLoadingRequest(false);

      }
    };
    if(filter.id_usuario_encargado > 0){
      fetchPost();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload, filter]);

  return { cut, loadingRequest };
};

// export const useGetInfoCut = () => {

//   const [loading, setLoading] = useState<boolean>(false);
//   const [isSuccess, setIsSuccess] = useState<boolean>(false);
//   const [cut, setCut] = useState<any>({
//     transaccion_caja: {
//       id: 0
//     }
//   });
//   const history = useHistory();
//   const boxCutInfo = async (box: any) => {
//     setLoading(false);
//     setIsSuccess(false);
//     try {
//       const response = await getInfoCut(box);
//       if(response){
//         setCut(response)
//       }
//       setLoading(true);
//       setIsSuccess(true);
//     } catch (error: any) {
//       if (error && error.response) {
//         const { message } = error.response.data;
//         SimpleAlert(message, 3000, 'error');
//         setLoading(true);
//       } else {
//         history.push('/error/500');
//       }
//     }
//   };
//   return { boxCutInfo, cut, loading, isSuccess  };
// };

export const useBoxCut = () => {

  const [loading, setLoading] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const history = useHistory();
  const boxCut = async (box: any) => {
    setLoading(true);
    try {
      await createBoxCut(box);
      SimpleAlert('Se cerró la caja exitosamente!', 3000, 'success');
      // setLoading(false);
      setIsSuccess(true);
    } catch (error: any) {
      if (error && error.response) {
        const { message } = error.response.data;
        SimpleAlert(message, 3000, 'error');
        setLoading(false);
      } else {
        history.push('/error/500');
      }
    }
  };
  return { boxCut, loading, isSuccess  };
};

export const useGetInfoPerUser = (reload: number, id_usuario_encargado: number) => {
  const [ dataUser, setDataUser] = useState<EntregaCaja>();
  const [loadingRequest, setLoadingRequest] = useState(true);

  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      const data = await getInfoPerUser(id_usuario_encargado)
        .then()
        .catch((error: any) => {
          history.push('/error/500');
        });
      if (data) {
        setDataUser(data);
        setLoadingRequest(false);
      }else{
        setDataUser({
          id: 0,
          id_usuario_encargado: 0,
          punto_venta: null,
          turno: null,
          transaccion_cuenta: null
      });
        setLoadingRequest(false);
      }
    };
    if(id_usuario_encargado > 0){
      fetchPost();
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload, id_usuario_encargado]);

  return { dataUser, loadingRequest };
};

export const useGetCutStarted = (reload: number) => {
  const [cutList, setCutList] = useState<any[]>([]);
  const [loadingRequest, setLoadingRequest] = useState<boolean>(true);
  const history = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      setLoadingRequest(true);
      try {
        const boxes = await getCutStarted();
        setCutList(boxes);
        setLoadingRequest(false);
      } catch (error) {
        history.push('/error/500');
      }
    };
    fetchData();
  }, [reload]);

  return { cutList, loadingRequest };
};

export const useFinishDayDelivery = () => {

  const [loading, setLoading] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const history = useHistory();
  const finishDayDelivery = async (id_dia: number) => {
    setLoading(true);
    setIsSuccess(false);
    try {
      await createFinishDayDelivery(id_dia);
      SimpleAlert('Se cerró el día exitosamente!', 3000, 'success');
      setLoading(false);
      setIsSuccess(true);
    } catch (error: any) {
      if (error && error.response) {
        const { message } = error.response.data;
        SimpleAlert(message, 3000, 'error');
        setLoading(false);
      } else {
        history.push('/error/500');
      }
    }
  };
  return { finishDayDelivery, loading, isSuccess, setLoading  };
};

export const useGetDetailInit = (reload: number, id: number) => {
  const [detail, setDetail] = useState<any>();
  const [loadingRequest, setLoadingRequest] = useState(true);

  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      const data = await getDetailInit(id)
        .then()
        .catch((error: any) => {
          history.push('/error/500');
        });
      if (data) {
        setDetail(data);
        setLoadingRequest(false);
      }
    };
    if(id > 0){
      fetchPost();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload, id]);

  return { detail, loadingRequest };
};


export const useGetDetailFinish = (reload: number, id: number) => {
  const [detail, setDetail] = useState<any>();
  const [loadingRequest, setLoadingRequest] = useState(true);

  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      const data = await getDetailFinish(id)
        .then()
        .catch((error: any) => {
          history.push('/error/500');
        });
      if (data) {
        setDetail(data);
        setLoadingRequest(false);
      }
    };
    if(id > 0){
      fetchPost();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload, id]);

  return { detail, loadingRequest };
};