import React, { useState } from "react";
import { useFormik } from "formik";
import { SimpleAlert } from "../../../../../rbts/helpers";
import { ProductsList } from "../sections/ProductsList";
import { useCreateNewSale } from "../hooks/SalesSupplierHook";

type Props = {
  supplierId: number;
  dayId: number;
  handleClose: () => void;
};

interface InitialValues {
  id_punto_venta: number;
  id_dia: number;
  detalle: any[];
}
const SalesSupplierForm: React.FC<Props> = ({
  supplierId,
  dayId,
  handleClose,
}) => {
  const [productsList, setProductsList] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const { insertData } = useCreateNewSale();

  const initValues: InitialValues = {
    id_punto_venta: supplierId,
    detalle: [],
    id_dia: dayId,
  };

  const SalesSupplierForm = useFormik({
    initialValues: initValues,
    enableReinitialize: true,
    onSubmit: async (values) => {
      setLoading(true);

      if (!productsList.length && supplierId) {
        setLoading(false);
        SimpleAlert(
          "Es necesario tener al menos un producto configurado para generar la venta",
          3000,
          "error"
        );
      } else if (!supplierId && productsList.length) {
        setLoading(false);
        SimpleAlert("El puesto es requerido", 3000, "error");
      } else if (!supplierId && !productsList.length) {
        setLoading(false);
        SimpleAlert(
          "Es necesario seleccionar un puesto y  tener al menos un producto configurado para generar la venta",
          3000,
          "error"
        );
      } else {
        const detailProducts: any = [];

        productsList.map((item: any) => {
          let obj: any = {};
          obj.cantidad = item.cantidad;
          obj.precio_unitario = item.precio;
          obj.id_producto = item.id;
          detailProducts.push(obj);
        });

        values.detalle = detailProducts;

        try {
          await insertData(values);
          setLoading(false);
          handleClose();
        } catch (error) {
          setLoading(false);
        }
      }
    },
  });

  const updatePriceProducts = (productos: any) => {
    setProductsList(productos);
  };

  return (
    <>
      <div className="card mb-6 mb-xl-9">
        <div className="card-body">
          <form
            id="kt_account_form"
            className="form"
            action="#"
            noValidate
            onSubmit={SalesSupplierForm.handleSubmit}
          >
            <div className="row mt-5">
              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                <ProductsList
                  data={productsList}
                  onGetDataProducts={updatePriceProducts}
                />
              </div>
            </div>

            <div className="d-flex justify-content-end mt-10">
              <button
                // style={{ cursor: "no-drop" }}
                type="submit"
                className="btn btn-primary"
                data-kt-users-modal-action="submit"
                disabled={loading}
              >
                {!loading && (
                  <span className="indicator-label">Finalizar venta</span>
                )}
                {loading && (
                  <span
                    className="indicator-progress"
                    style={{ display: "block" }}
                  >
                    Espere por favor...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
export { SalesSupplierForm };
