import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import {
  createNewSale,
  getAllProductsTypeSupplies,
  getAllSalesSuppliers,
  getAllSuppliersTypeJob,
  getSaleSupplierById,
} from "../../../../service/sales/Sales";
import { SelectFormat } from "../../../../../rbts/helpers/models/GenericModel";
import { getAllDaysSelect } from "../../../../service/box_delivery";
import { SimpleAlert } from "../../../../../rbts/helpers";

export const useSelectProducts = () => {
  const [products, setProducts] = useState<SelectFormat[]>([]);
  const [loadingRequest, setLoadingRequest] = useState<boolean>(true);
  const history = useHistory();

  useEffect(() => {
    const fetchPost = async () => {
      const res = await getAllProductsTypeSupplies()
        .then()
        .catch((error) => {
          history.push("/error/500");
        });
      if (res) {
        setProducts(res);
        setLoadingRequest(false);
      }
    };

    fetchPost();
  }, []);

  return { products, loadingRequest };
};

export const useSelectSuppliers = () => {
  const [suppliers, setSuppliers] = useState<SelectFormat[]>([]);
  const [loadingRequest, setLoadingRequest] = useState<boolean>(true);
  const history = useHistory();
  let arr: any = [];

  useEffect(() => {
    const fetchPost = async () => {
      const res = await getAllSuppliersTypeJob()
        .then()
        .catch((error) => {
          history.push("/error/500");
        });
      if (res) {
        setSuppliers(res);
        setLoadingRequest(false);
      }
    };

    fetchPost();
  }, []);

  return { suppliers, loadingRequest };
};

export const useCreateNewSale = () => {
  const [loadingInsert, setLoadingInsert] = useState<boolean>(false);

  const insertData = async (body: any) => {
    setLoadingInsert(true);
    try {
      await createNewSale(body);
      setLoadingInsert(false);
      SimpleAlert("La venta se ha registrado correctamente", 3000, "success");
    } catch (err: any) {
      setLoadingInsert(false);
      let resMessageToast: string = "";
      if (err.response && err.response.data && err.response.data.message) {
        resMessageToast = err.response.data.message;
      } else {
        resMessageToast =
          "Hubo un problema para realizar la venta, intentalo nuevamente.";
      }
      SimpleAlert(resMessageToast, 3000, "error");
      throw resMessageToast;
    }
  };

  return { insertData, loadingInsert };
};

export const useGetAllDaysSelect = (reload?: number) => {
  const [catalog, setCatalog] = useState<any>();
  const [loadingRequest, setLoadingRequest] = useState(true);

  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      const data = await getAllDaysSelect()
        .then()
        .catch((error: any) => {
          history.push("/error/500");
        });
      if (data) {
        setCatalog(data);
        setLoadingRequest(false);
      }
    };
    fetchPost();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  return { catalog, loadingRequest };
};

export const useGetAllSalesSupplier = (filter: any) => {
  const [data, setData] = useState<any>();
  const [loadingRequest, setLoadingRequest] = useState(true);

  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      const data = await getAllSalesSuppliers(filter)
        .then()
        .catch((error: any) => {
          history.push("/error/500");
        });
      if (data) {
        setData(data.rows);
        setLoadingRequest(false);
      }
    };
    if (filter && filter?.id_dia > 0) {
      fetchPost();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  return { data, loadingRequest };
};

export const useGetSaleSupplierById = (id: number) => {
  const [data, setData] = useState<any>();
  const [loadingRequest, setLoadingRequest] = useState(true);

  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      const data = await getSaleSupplierById(id)
        .then()
        .catch((error: any) => {
          history.push("/error/500");
        });
      if (data) {
        setData(data);
        setLoadingRequest(false);
      }
    };

    if (id > 0) {
      fetchPost();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return { data, loadingRequest };
};
