import axios from 'axios'
const API_URL = process.env.REACT_APP_API;

export const getTicketByFolio = async (filter: any) => {
  try {
    const { ticketcash } = filter;
    const response = await axios.get(`${API_URL}tickets/folio/${ticketcash}`);
    return response.data.doc.data;
  } catch (err) {
    throw err;
  }
}
export const createEnlistmentTicket = async (values: any) => {
  try {
    const {telefono_format, folio, nombre, enrolar } = values;
    const response = await axios.put(`${API_URL}tickets/cliente/enrolar`,{
      folio: folio,
      nombre: nombre,
      telefono: telefono_format,
      enrolar: enrolar
    });
    return response;
  } catch (err) {
    console.error("Error:", err);
    throw err;
  }
}

export const getBalanceRecovery = async (values: any) => {
  try {
    const {folio, telefono_format } = values;
    const response = await axios.post(`${API_URL}tickets/consultar/saldo`,{
      folio: folio,
      telefono: telefono_format,
    });
    return response.data.doc.data;
  } catch (err) {
    console.error("Error:", err);
    throw err;
  }
}

export const createCancelTicket = async (values: any) => {
  try {
    const {id, folio_nuevo } = values;
    const response = await axios.put(`${API_URL}tickets/cliente/cancelar`,{
      id: id,
      folio_nuevo: folio_nuevo,
    });
    return response;
  } catch (err) {
    console.error("Error:", err);
    throw err;
  }
}



// export const getTicketByFolio = async (filter: any) => {
//   try {
//     const { ticketcash } = filter;
//     const response = await axios.post(`${API_URL}tickets/ticketByUUID`,{
//       uuid: ticketcash
//     });
//     return response.data.doc.data;
//   } catch (err) {
//     throw err;
//   }
// }

