import React, { useState } from 'react';
import { Modal } from 'react-bootstrap-v5';
import { KTSVG, SimpleAlert, formatPrice } from '../../../../../rbts/helpers';
import { useGetDetailFinish } from '../../box_delivery/hooks';

type Props = {
  show: boolean;
  id_row: number;
  handleClose: (updateGrid: boolean) => void;
};

const DetailModal: React.FC<Props> = ({ show, id_row, handleClose }) => {
  const [loading, setLoading] = useState(false);

  const [reloadDay, setReloadDay] = useState(Math.random() * 40);
  const { detail, loadingRequest } = useGetDetailFinish(reloadDay, id_row);
  return (
    <Modal
      id="kt_modal_detail"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered modal-lg"
      show={show}
      onHide={() => {
        handleClose(false);
      }}
    >
      <div className="modal-content">
        <div className="modal-header ">
          <h2> Información de la caja</h2>
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            onClick={() => {
              handleClose(false);
            }}
          >
            <KTSVG
              className="svg-icon-2"
              path="/media/icons/duotune/arrows/arr061.svg"
            />
          </div>
        </div>
        <div className="modal-body">
          {detail && (
            <>
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                  <div className="d-flex mb-5">
                    <div className="" style={{ width: '40%' }}>
                      <div className="d-flex">
                        <label className="form-label w-auto">
                          Fecha administrativa:
                        </label>

                        <div className="fw-bold fs-6 text-gray-600 ms-2">
                          {detail.dia_venta && detail.dia_venta.fecha}
                        </div>
                      </div>
                    </div>
                    <div className="" style={{ width: '35%' }}>
                      <div className="d-flex">
                        <label className="form-label w-auto">Cajero:</label>

                        <div className=" fw-bold fs-6 text-gray-600  ms-2">
                          {detail.user_encargado &&
                            detail.user_encargado.nombre_completo}
                        </div>
                      </div>
                    </div>
                    <div className="" style={{ width: '25%' }}>
                      <div className="d-flex">
                        <label className="form-label w-auto">Truno:</label>

                        <div className="fw-bold fs-6 text-gray-600 ms-2">
                          {detail.turno && detail.turno.nombre}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                  <div className="d-flex">
                    <div
                      className="w-100 p-5 border-dashed"
                      style={{ borderRadius: '10px' }}
                    >
                      <div className="d-flex align-items-center mb-8">
                        <i className="fa fa-genderless text-danger fs-2 me-2"></i>
                        <span className="fs-5 text-gray-700">
                          Seccion inicial
                        </span>
                      </div>
                      <div className="d-flex flex-stack mb-3">
                        <label className="form-label w-auto">
                          Efectivo inicial:
                        </label>

                        <div className="text-end fw-bold fs-6 text-gray-600">
                          {`${formatPrice(
                            detail.efectivo_apertura
                              ? Number(detail.efectivo_apertura)
                              : 0,
                            'es-MX',
                            'MXN',
                            2,
                            2
                          )}`}
                        </div>
                      </div>
                      {detail &&
                      detail.entrega_caja_producto &&
                      detail.entrega_caja_producto.length > 0 ? (
                        detail.entrega_caja_producto.map(
                          (row: any, index: any) => (
                            <>
                              <div className="d-flex flex-stack mb-3">
                                <label className="form-label w-auto">
                                  {row.producto.nombre || 'Sin nombre'}
                                  {':'}
                                </label>

                                <div className="text-end fw-bold fs-6 text-gray-600">
                                  {row.cantidad || 0}
                                </div>
                              </div>
                            </>
                          )
                        )
                      ) : (
                        <div className="d-flex flex-stack mb-3">
                          <label className="form-label w-auto">
                            Sin registros:
                          </label>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                  <div className="d-flex mt-5">
                    <div
                      className="w-100 p-5 border-dashed"
                      style={{ borderRadius: '10px' }}
                    >
                      <div className="d-flex align-items-center mb-8">
                        <i className="fa fa-genderless text-success fs-2 me-2"></i>
                        <span className="fs-5 text-gray-700">
                          Seccion ventas
                        </span>
                      </div>

                      {detail &&
                      detail.inventario_ventas &&
                      detail.inventario_ventas.length > 0 ? (
                        detail.inventario_ventas.map((row: any, index: any) => (
                          <>
                            <div className="d-flex flex-stack mb-3">
                              <label className="form-label w-auto">
                                {row.producto.nombre || 'Sin nombre'}
                                {':'}
                              </label>

                              <div className="text-end fw-bold fs-6 text-gray-600">
                                {row.cantidad_total || 0}
                              </div>
                            </div>
                          </>
                        ))
                      ) : (
                        <div className="d-flex flex-stack mb-3">
                          <label className="form-label w-auto">
                            Sin registros:
                          </label>
                        </div>
                      )}
                      <div className="d-flex flex-stack mb-3">
                        <label className="form-label w-auto">
                          Ventas efectivo:
                        </label>

                        <div className="text-end fw-bold fs-6 text-gray-600">
                          {`${formatPrice(
                            detail.transaccion_cuenta.importe_efectivo
                              ? Number(
                                  detail.transaccion_cuenta.importe_efectivo
                                )
                              : 0,
                            'es-MX',
                            'MXN',
                            2,
                            2
                          )}`}
                        </div>
                      </div>

                      <div className="d-flex flex-stack mb-3">
                        <label className="form-label w-auto">
                          Ventas con tarjeta:
                        </label>

                        <div className="text-end fw-bold fs-6 text-gray-600">
                          {`${formatPrice(
                            detail.transaccion_cuenta.importe_tarjeta
                              ? Number(
                                  detail.transaccion_cuenta.importe_tarjeta
                                )
                              : 0,
                            'es-MX',
                            'MXN',
                            2,
                            2
                          )}`}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                  <div className="d-flex mt-5">
                    <div
                      className="w-100 p-5 border-dashed"
                      style={{ borderRadius: '10px' }}
                    >
                      <div className="d-flex align-items-center mb-8">
                        <i className="fa fa-genderless text-primary fs-2 me-2"></i>
                        <span className="fs-5 text-gray-700">
                          Seccion final
                        </span>
                      </div>
                      <div className="d-flex">
                        <div className="w-100">
                          <div className="d-flex flex-stack mb-5">
                            <label
                              className="form-label"
                              style={{
                                width: '140px',
                              }}
                            ></label>

                            <div
                              className="text-end fw-bold fs-7 text-gray-600"
                              style={{
                                width: '120px',
                              }}
                            >
                              Solicitado
                            </div>
                            <div
                              className="text-end fw-bold fs-7 text-gray-600"
                              style={{
                                width: '120px',
                              }}
                            >
                              Entregado
                            </div>

                            <div
                              className="text-end fw-bold fs-7 text-gray-600"
                              style={{
                                width: '120px',
                              }}
                            >
                              Diferencias
                            </div>
                          </div>

                          {detail &&
                          detail.entrega_caja_producto &&
                          detail.entrega_caja_producto.length > 0 ? (
                            detail.entrega_caja_producto.map(
                              (row: any, index: any) => (
                                <>
                                  <div className="d-flex flex-stack mb-3">
                                    <label
                                      className="form-label"
                                      style={{
                                        width: '140px',
                                      }}
                                    >
                                      {row.producto.nombre || 'Sin nombre'}
                                      {':'}
                                    </label>

                                    <div
                                      className="text-end fw-bold fs-6 text-gray-600"
                                      style={{
                                        width: '120px',
                                      }}
                                    >
                                      {row.cantidad}
                                    </div>
                                    <div
                                      className="text-end fw-bold fs-6 text-gray-600"
                                      style={{
                                        width: '120px',
                                      }}
                                    >
                                      {row.cantidad_devuelta}
                                    </div>
                                    <div
                                      className="text-end fw-bold fs-6 text-gray-600"
                                      style={{
                                        width: '120px',
                                      }}
                                    >
                                      {row.cantidad - row.cantidad_devuelta}
                                    </div>
                                  </div>
                                </>
                              )
                            )
                          ) : (
                            <div className="d-flex flex-stack mb-3">
                              <label className="form-label w-auto text-gray-500">
                                Sin ventas
                              </label>
                            </div>
                          )}

                          <div className="d-flex flex-stack mb-3">
                            <label
                              className="form-label"
                              style={{
                                width: '140px',
                              }}
                            >
                              Total efectivo:
                            </label>

                            <div
                              className="text-end fw-bold fs-6 text-gray-600"
                              style={{
                                width: '120px',
                              }}
                            >
                              {`${formatPrice(
                                (detail.efectivo_apertura
                                  ? Number(detail.efectivo_apertura)
                                  : 0) +
                                  (detail.transaccion_cuenta.importe_efectivo
                                    ? Number(
                                        detail.transaccion_cuenta
                                          .importe_efectivo
                                      )
                                    : 0),
                                'es-MX',
                                'MXN',
                                2,
                                2
                              )}`}
                            </div>
                            <div
                              className="text-end fw-bold fs-6 text-gray-600"
                              style={{
                                width: '120px',
                              }}
                            >
                              {`${formatPrice(
                                detail.efectivo_devuelto
                                  ? Number(detail.efectivo_devuelto)
                                  : 0,
                                'es-MX',
                                'MXN',
                                2,
                                2
                              )}`}
                            </div>
                            <div
                              className="text-end fw-bold fs-6 text-gray-600"
                              style={{
                                width: '120px',
                              }}
                            >
                              {`${formatPrice(
                                (detail.efectivo_apertura
                                  ? Number(detail.efectivo_apertura)
                                  : 0) +
                                  (detail.transaccion_cuenta.importe_efectivo
                                    ? Number(
                                        detail.transaccion_cuenta
                                          .importe_efectivo
                                      )
                                    : 0) -
                                  detail.efectivo_devuelto,
                                'es-MX',
                                'MXN',
                                2,
                                2
                              )}`}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          <div className="text-center pt-8">
            <button
              type="reset"
              className="btn btn-light me-3"
              data-kt-users-modal-action="cancel"
              onClick={() => {
                handleClose(false);
              }}
            >
              Cerrar
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export { DetailModal };
