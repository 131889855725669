import React, { useState, useEffect } from "react";
import { KTSVG, formatPrice } from "../../../../rbts/helpers";
import { Modal } from "react-bootstrap-v5";
import { useGetConciliationById } from "../hooks/ConsilationHook";
import { DetalleConciliacion } from "../models/ConciliationModel";
import CountUp from "react-countup";

type Props = {
  show: boolean;
  handleClose: (updateGrid: boolean) => void;
  concilation: number;
  strDate: string;
};

const DetailConciliationModal: React.FC<Props> = ({
  show,
  handleClose,
  concilation,
  strDate,
}) => {
  const { conciliationDetail, loadingRequest } =
    useGetConciliationById(concilation);
  const [detail, setDetail] = useState<any>([]);
  const [totalAmount, setTotalAmount] = useState<number>(0);
  const [totalElectronicBalance, setTotalElectronicBalance] =
    useState<number>(0);
  const [totalBank, setTotalBank] = useState<number>(0);
  const [totalsupplier, setTotalsupplier] = useState<number>(0);
  const [totalRawMaterial, setTotalRawMaterial] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [splitBank, setSplitBank] = useState<number>(0);
  const [splitSailPoint, setSplitSailPoint] = useState<number>(0);
  const [supplierName, setSupplierName] = useState<string>("");

  useEffect(() => {
    if (conciliationDetail && concilation > 0) {
      const {
        total_cantidad,
        total_saldo_electronico,
        total_banco,
        total_punto_venta,
        total_materia_prima,
        total_a_pagar,
        split_banco,
        split_punto_venta,
        punto_venta,
      }: any = conciliationDetail;
      setTotalAmount(total_cantidad);
      setTotalElectronicBalance(total_saldo_electronico);
      setTotalBank(total_banco);
      setTotalsupplier(total_punto_venta);
      setTotalRawMaterial(total_materia_prima);
      setTotal(total_a_pagar);
      setSplitBank(split_banco ? split_banco : 0);
      setSplitSailPoint(split_punto_venta ? split_punto_venta : 0);
      setSupplierName(punto_venta ? punto_venta?.nombre : "");
      setDetail(conciliationDetail);
    } else {
      setDetail([]);
    }
  }, [conciliationDetail]);

  useEffect(() => {
    if (!show) {
      setDetail([]);
    }
  }, [show]);

  return (
    <Modal
      id="kt_modal_create"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-1000px"
      show={show}
      onHide={() => {
        handleClose(false);
      }}
    >
      <div className="modal-content">
        <div className="modal-header">
          <h2>{"Detalle Conciliación"}</h2>
          <h3>
            <span className="card-label fw-bold text-gray-800 ">
              Fecha administrativa:
            </span>
            <span className="fs-4 text-gray-700 ms-4">{strDate}</span>
          </h3>
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            onClick={() => {
              handleClose(false);
            }}
          >
            <KTSVG
              className="svg-icon-2"
              path="/media/icons/duotune/arrows/arr061.svg"
            />
          </div>
        </div>
        <div className="modal-body scroll-y mx-5 ">
          <div className="table-responsive min-h-300px me-n5 pe-5 scroll-y  mt-5">
            {loadingRequest ? (
              <div className="w-100 text-center">
                <span
                  className="indicator-progress"
                  style={{ display: "block" }}
                >
                  Cargando...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              </div>
            ) : (
              <>
                <h3 className="card-title">
                  <span className="card-label fw-bold text-gray-800">
                    Proveedor:
                  </span>
                  <span className="fs-4 text-gray-700"> {supplierName}</span>
                </h3>
                <table className="table table-border table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 me-n5 pe-5 w-100">
                  <thead>
                    <tr className="text-gray-800 fs-7">
                      <th className="w-300px"></th>
                      <th className="w-250px"></th>
                      <th className="w-250px"></th>
                      <th className="w-250px"></th>
                      <th className="w-250px text-primary"></th>
                      <th className="w-250px text-primary">
                        {" "}
                        Split {`${splitSailPoint}/${splitBank}`}
                      </th>
                      <th className="w-500px text-primary">Deuda MP </th>
                    </tr>
                    <tr className="text-gray-800 fs-7">
                      <th className="w-300px">Productos</th>
                      <th className="w-250px">Precio</th>
                      <th className="w-100px">Cantidad</th>
                      <th className="w-250px">Saldo electrónico</th>
                      <th className="w-250px">Banco</th>
                      <th className="w-250px">Proveedor</th>
                      <th className="w-250px">Materia prima</th>
                      <th className="w-250px">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {detail && detail?.detalle?.length ? (
                      detail.detalle.map(
                        (item: DetalleConciliacion, index: number) => (
                          <tr key={index}>
                            <td>{item.producto_nombre}</td>
                            <td>
                              {formatPrice(
                                item.precio ? item.precio : 0,
                                "es-MX",
                                "MXN",
                                2,
                                2
                              )}
                            </td>
                            <td>{item.cantidad}</td>
                            <td>
                              {formatPrice(
                                item.saldo_electronico
                                  ? item.saldo_electronico
                                  : 0,
                                "es-MX",
                                "MXN",
                                2,
                                2
                              )}
                            </td>
                            <td>
                              {formatPrice(
                                item.monto_banco ? item.monto_banco : 0,
                                "es-MX",
                                "MXN",
                                2,
                                2
                              )}
                            </td>
                            <td>
                              {formatPrice(
                                item.monto_punto_venta
                                  ? item.monto_punto_venta
                                  : 0,
                                "es-MX",
                                "MXN",
                                2,
                                2
                              )}
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td colSpan={9} className="text-center text-muted">
                          Sin registros
                        </td>
                      </tr>
                    )}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td></td>
                      <td className="text-bold fs-6">
                        <span>Total</span>
                      </td>
                      <td>{totalAmount}</td>
                      <td className="text-success">
                        <CountUp
                          end={totalElectronicBalance}
                          duration={1}
                          prefix="$"
                          decimals={2}
                        />
                      </td>
                      <td className="text-success">
                        <CountUp
                          end={totalBank}
                          duration={1}
                          prefix="$"
                          decimals={2}
                        />
                      </td>
                      <td className="text-success">
                        <CountUp
                          end={totalsupplier}
                          duration={1}
                          prefix="$"
                          decimals={2}
                        />
                      </td>
                      <td className="text-primary">
                        <CountUp
                          end={totalRawMaterial}
                          duration={1}
                          prefix="$"
                          decimals={2}
                        />
                      </td>
                      <td className="text-primary">
                        <CountUp
                          end={total}
                          duration={1}
                          prefix="$"
                          decimals={2}
                        />
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </>
            )}
          </div>
          <div className="d-flex justify-content-end mt-10">
            <button
              type="button"
              className="btn btn-primary me-3"
              data-kt-users-modal-action="cancel"
              onClick={() => {
                handleClose(false);
              }}
            >
              Cerrar
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export { DetailConciliationModal };
