import axios from "axios";
import { SelectFormat } from "../../../rbts/helpers/models/GenericModel";
const API_URL = process.env.REACT_APP_API;

export async function getAllConciliations() {
  const response = await axios.get(`${API_URL}conciliaciones`);
  const data = response.data.doc.data;
  return data;
}

export async function getConciliationBySupplier(id: number) {
  const response = await axios.get(
    `${API_URL}conciliaciones/vistaPrevia?id_punto_venta=${id}`
  );
  const data = response.data.doc.data;
  return data;
}

export async function executeConciliation(body: any) {
  const { id_punto_venta } = body;
  const response = await axios.post(
    `${API_URL}conciliaciones/confirmar?id_punto_venta=${id_punto_venta}`
  );
  const data = response.data.doc.data;
  return data;
}

export async function getConciliationById(id: number) {
  const response = await axios.get(`${API_URL}conciliaciones/${id}`);
  const data = response.data.doc.data;
  return data;
}

export async function closeCurrentDay(dayId: number) {
  const body = {
    id_dia: dayId,
  };
  const response = await axios.put(`${API_URL}diaventa/cerrar`, body);
  const data = response.data.doc.data;
  return data;
}

export async function getAllSuppliersOpened() {
  const response = await axios.get(
    `${API_URL}puntoVenta/diaVenta/list?isAbierto=true`
  );
  const data = response.data.doc.data.rows;
  let select: SelectFormat[] = [];
  data.forEach((item: any) => {
    const selectCustom = {
      id: Number(item.id),
      value: item.id_punto_venta,
      label: `${item.nombre_punto_venta}`,
      data: item,
    };
    select.push(selectCustom);
  });

  return select;
}
