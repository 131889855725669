import React, { useState, useEffect } from "react";
import Select from "react-select";
import { styleSelect } from "../../../../../rbts/assets/ts/_utils";

import {
  formatPrice,
} from "../../../../../rbts/helpers";
import { useGetInfoCutPerDate } from "../hooks";
import { useGetAllDaysClosed } from "../../../treasury/day_cut/hooks";

type Props = {};

const View: React.FC<Props> = ({}) => {

  const [ currentDay, setCurrentDay] = useState<any | []>(null);
  const { catalog: daysClosed, loadingRequest } = useGetAllDaysClosed();
  const { detail, loadingRequest: loadingCUT, setDetail } = useGetInfoCutPerDate(currentDay);

  const onChangeDaysSelect = (selectoption: any) => {
    if (selectoption) {
      setCurrentDay(selectoption);
    } else {
      setCurrentDay(null);
      setDetail(null);
    }
  };

  return (
    <>
      <div className="d-flex flex-column flex-xl-row">
        <div className="flex-lg-row-fluid">
          <div className="card mb-6 mb-xl-9">
            <div className="card-header border-0">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bold text-gray-800">
                  Fecha administrativa:
                </span>
              </h3>
            </div>
            
            <div className="card-body">
              <div className=" col-md-4">
              {/* <Select
                  options={daysClosed}
                  value={currentDay}
                  styles={styleSelect}
                  placeholder={"Seleccione un día"}
                  isSearchable={true}
                  noOptionsMessage={() => {
                    return <span>{"No se encontraron registros"}</span>;
                  }}
                  onChange={onChangeDaysSelect}
                  isLoading={loadingRequest}
                  isClearable
                  backspaceRemovesValue
                /> */}
              </div>
            </div>
          </div>
          {detail && (
            <>
            <div className="card mb-6 mb-xl-9">
            
          <div className="card-body">
              <div className="row">
                
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                  <div className="d-flex">
                    <div
                      className="w-100 p-5 border-dashed"
                      style={{ borderRadius: '10px' }}
                    >
                      <div className="d-flex align-items-center mb-8">
                        <i className="fa fa-genderless text-danger fs-2 me-2"></i>
                        <span className="fs-5 text-gray-700">
                          Seccion inicial
                        </span>
                      </div>
                      <div className="d-flex flex-stack mb-3">
                        <label className="form-label w-auto">
                          Efectivo inicial:
                        </label>

                        <div className="text-end fw-bold fs-6 text-gray-600">
                          {`${formatPrice(
                            detail.efectivo_apertura
                              ? Number(detail.efectivo_apertura)
                              : 0,
                            'es-MX',
                            'MXN',
                            2,
                            2
                          )}`}
                        </div>
                      </div>
                      {detail &&
                      detail.entrega_caja_producto &&
                      detail.entrega_caja_producto.length > 0 ? (
                        detail.entrega_caja_producto.map(
                          (row: any, index: any) => (
                            <>
                              <div className="d-flex flex-stack mb-3">
                                <label className="form-label w-auto">
                                  {row.producto.nombre || 'Sin nombre'}
                                  {':'}
                                </label>

                                <div className="text-end fw-bold fs-6 text-gray-600">
                                  {row.cantidad || 0}
                                </div>
                              </div>
                            </>
                          )
                        )
                      ) : (
                        <div className="d-flex flex-stack mb-3">
                          <label className="form-label w-auto">
                            Sin registros:
                          </label>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                  <div className="d-flex mt-5">
                    <div
                      className="w-100 p-5 border-dashed"
                      style={{ borderRadius: '10px' }}
                    >
                      <div className="d-flex align-items-center mb-8">
                        <i className="fa fa-genderless text-success fs-2 me-2"></i>
                        <span className="fs-5 text-gray-700">
                          Seccion ventas
                        </span>
                      </div>

                      {detail &&
                      detail.inventario_ventas &&
                      detail.inventario_ventas.length > 0 ? (
                        detail.inventario_ventas.map((row: any, index: any) => (
                          <>
                            <div className="d-flex flex-stack mb-3">
                              <label className="form-label w-auto">
                                {row.producto.nombre || 'Sin nombre'}
                                {':'}
                              </label>

                              <div className="text-end fw-bold fs-6 text-gray-600">
                                {row.cantidad_total || 0}
                              </div>
                            </div>
                          </>
                        ))
                      ) : (
                        <div className="d-flex flex-stack mb-3">
                          <label className="form-label w-auto">
                            Sin registros:
                          </label>
                        </div>
                      )}
                      <div className="d-flex flex-stack mb-3">
                        <label className="form-label w-auto">
                          Ventas efectivo:
                        </label>

                        <div className="text-end fw-bold fs-6 text-gray-600">
                          {`${formatPrice(
                            detail.transaccion_cuenta.importe_efectivo
                              ? Number(
                                  detail.transaccion_cuenta.importe_efectivo
                                )
                              : 0,
                            'es-MX',
                            'MXN',
                            2,
                            2
                          )}`}
                        </div>
                      </div>

                      <div className="d-flex flex-stack mb-3">
                        <label className="form-label w-auto">
                          Ventas con tarjeta:
                        </label>

                        <div className="text-end fw-bold fs-6 text-gray-600">
                          {`${formatPrice(
                            detail.transaccion_cuenta.importe_tarjeta
                              ? Number(
                                  detail.transaccion_cuenta.importe_tarjeta
                                )
                              : 0,
                            'es-MX',
                            'MXN',
                            2,
                            2
                          )}`}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                  <div className="d-flex mt-5">
                    <div
                      className="w-100 p-5 border-dashed"
                      style={{ borderRadius: '10px' }}
                    >
                      <div className="d-flex align-items-center mb-8">
                        <i className="fa fa-genderless text-primary fs-2 me-2"></i>
                        <span className="fs-5 text-gray-700">
                          Seccion final
                        </span>
                      </div>
                      <div className="d-flex">
                        <div className="w-100">
                          <div className="d-flex flex-stack mb-5">
                            <label
                              className="form-label"
                              style={{
                                width: '140px',
                              }}
                            ></label>

                            <div
                              className="text-end fw-bold fs-7 text-gray-600"
                              style={{
                                width: '120px',
                              }}
                            >
                              Solicitado
                            </div>
                            <div
                              className="text-end fw-bold fs-7 text-gray-600"
                              style={{
                                width: '120px',
                              }}
                            >
                              Entregado
                            </div>

                            <div
                              className="text-end fw-bold fs-7 text-gray-600"
                              style={{
                                width: '120px',
                              }}
                            >
                              Diferencias
                            </div>
                          </div>

                          {detail &&
                          detail.entrega_caja_producto &&
                          detail.entrega_caja_producto.length > 0 ? (
                            detail.entrega_caja_producto.map(
                              (row: any, index: any) => (
                                <>
                                  <div className="d-flex flex-stack mb-3">
                                    <label
                                      className="form-label"
                                      style={{
                                        width: '140px',
                                      }}
                                    >
                                      {row.producto.nombre || 'Sin nombre'}
                                      {':'}
                                    </label>

                                    <div
                                      className="text-end fw-bold fs-6 text-gray-600"
                                      style={{
                                        width: '120px',
                                      }}
                                    >
                                      {row.cantidad}
                                    </div>
                                    <div
                                      className="text-end fw-bold fs-6 text-gray-600"
                                      style={{
                                        width: '120px',
                                      }}
                                    >
                                      {row.cantidad_devuelta}
                                    </div>
                                    <div
                                      className="text-end fw-bold fs-6 text-gray-600"
                                      style={{
                                        width: '120px',
                                      }}
                                    >
                                      {row.cantidad - row.cantidad_devuelta}
                                    </div>
                                  </div>
                                </>
                              )
                            )
                          ) : (
                            <div className="d-flex flex-stack mb-3">
                              <label className="form-label w-auto text-gray-500">
                                Sin ventas
                              </label>
                            </div>
                          )}

                          <div className="d-flex flex-stack mb-3">
                            <label
                              className="form-label"
                              style={{
                                width: '140px',
                              }}
                            >
                              Total efectivo:
                            </label>

                            <div
                              className="text-end fw-bold fs-6 text-gray-600"
                              style={{
                                width: '120px',
                              }}
                            >
                              {`${formatPrice(
                                (detail.efectivo_apertura
                                  ? Number(detail.efectivo_apertura)
                                  : 0) +
                                  (detail.transaccion_cuenta.importe_efectivo
                                    ? Number(
                                        detail.transaccion_cuenta
                                          .importe_efectivo
                                      )
                                    : 0),
                                'es-MX',
                                'MXN',
                                2,
                                2
                              )}`}
                            </div>
                            <div
                              className="text-end fw-bold fs-6 text-gray-600"
                              style={{
                                width: '120px',
                              }}
                            >
                              {`${formatPrice(
                                detail.efectivo_devuelto
                                  ? Number(detail.efectivo_devuelto)
                                  : 0,
                                'es-MX',
                                'MXN',
                                2,
                                2
                              )}`}
                            </div>
                            <div
                              className="text-end fw-bold fs-6 text-gray-600"
                              style={{
                                width: '120px',
                              }}
                            >
                              {`${formatPrice(
                                (detail.efectivo_apertura
                                  ? Number(detail.efectivo_apertura)
                                  : 0) +
                                  (detail.transaccion_cuenta.importe_efectivo
                                    ? Number(
                                        detail.transaccion_cuenta
                                          .importe_efectivo
                                      )
                                    : 0) -
                                  detail.efectivo_devuelto,
                                'es-MX',
                                'MXN',
                                2,
                                2
                              )}`}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </div>
            
            </div>
            </>
          )}




          
        </div>
      </div>
    </>
  );
};

export { View };
