import React, { useState, useEffect } from "react";
import { DonationsForm } from "./DonationsForm";
import { useGetAllDaysSelect } from "../hooks/DonationsHook";
import { DonationGrid } from "./DonationGrid";
import { useInitDayDelivery } from "../../treasury/box_delivery/hooks";

type Props = {};

const ViewAE: React.FC<Props> = ({}) => {
  const [showSaleForm, setShowSaleForm] = useState<boolean>(false);
  const [reloadInfo, setReloadInfo] = useState<number>(Math.random() * 40);
  const { catalog: day } = useGetAllDaysSelect(reloadInfo);
  const [strDate, setStrDate] = useState<string>("");
  const [currentDay, setCurrentDay] = useState<number>(0);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const { initDayDelivery } = useInitDayDelivery();

  useEffect(() => {
    if (day) {
      const {
        dia: { id },
        fecha_str,
      } = day;
      setStrDate(fecha_str);
      setCurrentDay(id);
    }
  }, [day]);

  useEffect(() => {
    if (currentDay) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [currentDay]);

  const closeSecction = () => {
    setShowSaleForm(false);
    setReloadInfo(Math.random() * 60);
  };

  return (
    <>
      <div className="d-flex flex-column flex-xl-row">
        <div className="col-xl-12">
          <div className={`card card-xxl-stretch mb-5 mb-xl-12`}>
            <div className="d-flex flex-column flex-xl-row">
              <div className="flex-lg-row-fluid">
                <div className="card mb-6 mb-xl-9">
                  <div className="card-header border-0">
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label fw-bold text-gray-800">
                        Iniciar día administrativo
                      </span>
                    </h3>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                        <div className="w-100 pe-5">
                          <div className="w-100 d-flex"></div>
                          <button
                            type="button"
                            title="Iniciar día"
                            disabled={currentDay ? true : false}
                            onClick={async () => {
                              try {
                                await initDayDelivery();
                                setReloadInfo(Math.random() * 50);
                              } catch (error) {
                                console.log("error");
                              }
                            }}
                            className={`btn w-200px ${"btn-success"}`}
                          >
                            <span className="indicator-label">
                              {"Iniciar día"}
                            </span>
                          </button>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                        <div className="w-100 pe-5">
                          <div className="w-100 d-flex">
                            <span
                              className="form-control border-0 text-muted fs-6 text-center"
                              style={{ paddingLeft: "0px" }}
                            >
                              {strDate}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                        <div className="w-100 pe-5">
                          <div className="w-100 d-flex"></div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                        <div className="w-100 pe-5">
                          <button
                            type="button"
                            title="Nueva venta"
                            disabled={isDisabled}
                            onClick={() => {
                              setShowSaleForm(!showSaleForm);
                            }}
                            className={`btn w-200px ${
                              !showSaleForm ? "btn-primary" : "btn-light"
                            }`}
                          >
                            <span className="indicator-label">
                              {!showSaleForm ? "Nuevo donativo" : "Cancelar"}
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {!showSaleForm ? (
            <div className={`card card-xxl-stretch mb-5 mb-xl-12`}>
              <div className="d-flex flex-column flex-xl-row">
                <div className="flex-lg-row-fluid">
                  <div className="card mb-6 mb-xl-9">
                    <div className="card-header border-0">
                      <h3 className="card-title align-items-start flex-column">
                        <span className="card-label fw-bold text-gray-800">
                          Donativos realizados
                        </span>
                      </h3>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                          <DonationGrid reloadGrid={reloadInfo}></DonationGrid>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          {showSaleForm ? (
            <div className="card">
              <div className="card-header pt-5">
                <h3 className="card-title align-items-start flex-column">
                  <span className="card-label fw-bold text-gray-800">
                    Donativos
                  </span>
                </h3>
              </div>
              <div className="card-body">
                <div className="row w-100 ">
                  <div className="col-md-12 ">
                    <DonationsForm
                      dayId={currentDay}
                      handleClose={closeSecction}
                    ></DonationsForm>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export { ViewAE };
