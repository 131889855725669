import React from "react";
import { useIntl } from "react-intl";
import { AsideMenuItem } from "./AsideMenuItem";
import { AsideMenuItemWithSub } from "./AsideMenuItemWithSub";
import { shallowEqual, useSelector } from "react-redux";
import { UserModel } from "../../../../app/modules/auth/models/UserModel";
import { RootState } from "../../../../setup/index";
import { userHasRole } from "../../../helpers";

export function AsideMenuMain() {
  const user: UserModel = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  ) as UserModel;
  const { roles: userRoles } = user;
  const intl = useIntl();

  return (
    <>
      {userHasRole(["ADMIN", "TESO", "SERVCLIENTE", "COMPRAS"], userRoles) && (
        <AsideMenuItem
          to="/dashboard"
          icon="/media/icons/duotune/graphs/gra008.svg"
          title={"Inicio"}
          fontIcon="bi-app-indicator"
        />
      )}
      {userHasRole(["ADMIN", "TESO", "SERVCLIENTE", "COMPRAS"], userRoles) && (
        <>
          {userHasRole(["ADMIN", "TESO"], userRoles) && (
            <AsideMenuItemWithSub
              icon="/media/icons/duotune/finance/fin010.svg"
              fontIcon="bi-layers"
              to="/tesoreria/"
              title="Tesorería"
              hasBullet={false}
            >
              <AsideMenuItem
                to="/tesoreria/entrega_caja/"
                icon="/media/icons/duotune/finance/fin010.svg"
                title="Iniciar día y caja"
                fontIcon="bi-app-indicator"
              />
              <AsideMenuItem
                to="/tesoreria/corte_caja/"
                title="Corte de caja y día"
                icon="/media/icons/duotune/finance/fin010.svg"
              />
              {/* <AsideMenuItem
                to="/tesoreria/cortes_dia/"
                title="Cortes del día"
                icon="/media/icons/duotune/finance/fin010.svg"
              /> */}
            </AsideMenuItemWithSub>
          )}
          {userHasRole(["ADMIN", "SERVCLIENTE"], userRoles) && (
            <>
              <AsideMenuItemWithSub
                icon="/media/icons/duotune/communication/com006.svg"
                fontIcon="bi-layers"
                to="/servicio_cliente/"
                title="Servicio al cliente"
                hasBullet={false}
              >
                <AsideMenuItem
                  to="/servicio_cliente/enrolamiento/"
                  icon="/media/icons/duotune/finance/fin003.svg"
                  title="Enrolamiento"
                  fontIcon="bi-app-indicator"
                />
                <AsideMenuItem
                  to="/servicio_cliente/recuperar_saldo/"
                  title="Recuperar saldo"
                  icon="/media/icons/duotune/finance/fin002.svg"
                />
                <AsideMenuItem
                  to="/servicio_cliente/consultar_saldo/"
                  title="Consultar saldo"
                  icon="/media/icons/duotune/finance/fin010.svg"
                />
              </AsideMenuItemWithSub>
            </>
          )}
          {userHasRole(["ADMIN", "COMPRAS"], userRoles) && (
            <>
              <AsideMenuItemWithSub
                icon="/media/icons/duotune/ecommerce/ecm005.svg"
                fontIcon="bi-layers"
                to="/compras/"
                title="Compras"
                hasBullet={false}
              >
                {/* <AsideMenuItem
                  to="/compras/acuerdos_proveedores/"
                  title="Acuerdo Proveedor"
                  icon="/media/icons/duotune/finance/fin013.svg"
                /> */}
                <AsideMenuItem
                  to="/compras/ventas_proveedores/"
                  title="Venta Proveedor"
                  icon="/media/icons/duotune/finance/fin013.svg"
                />
                <AsideMenuItem
                  to="/compras/iniciar_turno/"
                  title="Iniciar turno"
                  icon="/media/icons/duotune/general/gen012.svg"
                />
                <AsideMenuItem
                  to="/compras/conciliacion/"
                  title="Conciliación"
                  icon="/media/icons/duotune/finance/fin014.svg"
                />
              </AsideMenuItemWithSub>
              {userHasRole(["ADMIN"], userRoles) && (
                <AsideMenuItem
                  to="/donativos/"
                  title="Donativos"
                  icon="/media/icons/duotune/finance/fin011.svg"
                />
              )}
            </>
          )}

          {/* <AsideMenuItemWithSub
            icon="/media/icons/duotune/ecommerce/ecm005.svg"
            fontIcon="bi-layers"
            to="/operaciones/compras/"
            title="Compras"
            hasBullet={false}
          >
            <AsideMenuItem
              to="/operaciones/compras/acuerdos_proveedores/"
              icon="/media/icons/duotune/finance/fin012.svg"
              title="Proveedor y Acuerdos"
              fontIcon="bi-app-indicator"
            />
          </AsideMenuItemWithSub> */}
        </>
      )}
      {userHasRole(["ADMIN"], userRoles) && (


<>

<AsideMenuItemWithSub
          icon="/media/icons/duotune/files/fil003.svg"
          fontIcon="bi-layers"
          to="/reportes/"
          title="Reportes"
          hasBullet={false}
        >
          <AsideMenuItem
            to="/reportes/reporte_dia"
            title="Reporte corte por día"
            icon="/media/icons/duotune/files/fil003.svg"
          />
        </AsideMenuItemWithSub>

<AsideMenuItemWithSub
          icon="/media/icons/duotune/general/gen054.svg"
          fontIcon="bi-layers"
          to="/administracion/"
          title="Administración"
          hasBullet={false}
        >
          <AsideMenuItem
            to="/usuarios"
            title="Usuarios"
            icon="/media/icons/duotune/general/gen049.svg"
          />
        </AsideMenuItemWithSub>
</>
        
      )}
    </>
  );
}
