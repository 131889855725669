import React, { useState, useEffect } from "react";
import Select from "react-select";
import {
  useGetAllDaysSelect,
  useSelectSuppliers,
} from "../../sales/Suppliers/hooks/SalesSupplierHook";
import { ConciliationGrid } from "./ConciliationGrid";
import { styleSelect } from "../../../../rbts/assets/ts/_utils";
import { ConciliationForm } from "./ConciliationForm";
import {
  useExecuteCloseCurrentDay,
  useSelectSuppliersOpened,
} from "../hooks/ConsilationHook";

type Props = {};

const View: React.FC<Props> = ({}) => {
  const { suppliers, loadingRequest } = useSelectSuppliers();

  const [supplierSelected, setSupplierSelected] = useState<any | null>([]);
  const [showSaleForm, setShowSaleForm] = useState<boolean>(false);
  const [reloadInfo, setReloadInfo] = useState<number>(Math.random() * 40);
  const { catalog: day } = useGetAllDaysSelect(reloadInfo);
  const [strDate, setStrDate] = useState<string>("");
  const [currentDay, setCurrentDay] = useState<number>(0);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const { loadingCloseDay, closeDay } = useExecuteCloseCurrentDay();
  const [isDisabledBtnCloseDay, setIsDisabledBtnCloseDay] =
    useState<boolean>(false);

  useEffect(() => {
    if (day) {
      const {
        dia: { id },
        fecha_str,
      } = day;
      setStrDate(fecha_str);
      setCurrentDay(id);
      setIsDisabledBtnCloseDay(false);
    } else {
      setIsDisabledBtnCloseDay(true);
    }
  }, [day]);

  const handleSupplierOnChange = (selectedOption: any) => {
    if (selectedOption) {
      setSupplierSelected(selectedOption);
      setIsDisabled(false);
    } else {
      setSupplierSelected(null);
      setIsDisabled(true);
      setShowSaleForm(false);
    }
  };

  const closeSecction = () => {
    setShowSaleForm(false);
  };

  return (
    <>
      <div className="d-flex flex-column flex-xl-row">
        <div className="col-xl-12">
          <div className={`card card-xxl-stretch mb-5 mb-xl-12`}>
            <div className="d-flex flex-column flex-xl-row">
              <div className="flex-lg-row-fluid">
                <div className="card mb-6 mb-xl-9">
                  <div className="card-header border-0">
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label fw-bold text-gray-800">
                        Proveedor
                      </span>
                    </h3>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      {/* {isDisabled && isReconciled && supplierSelected ? (
                        <div className="alert alert-dismissible bg-light-primary d-flex flex-column flex-sm-row p-5 mb-10">
                          <div className="d-flex flex-column text-primary pe-0 pe-sm-10">
                            <span className="fs-6">
                              No es posible generar una conciliación, ya se ha
                              generado la del día actual
                            </span>
                          </div>
                        </div>
                      ) : null} */}
                      <div className="col-sm-12 col-md-4 col-lg-3 col-xl-3 col-xxl-3">
                        <div className="w-100 pe-5">
                          <div className="w-100 d-flex">
                            <label className="required form-label w-auto">
                              Nombre del puesto:{" "}
                            </label>
                          </div>
                          <Select
                            options={suppliers}
                            value={supplierSelected}
                            styles={styleSelect}
                            placeholder={"Seleccione"}
                            isSearchable={true}
                            noOptionsMessage={() => {
                              return (
                                <span>{"No se encontraron registros"}</span>
                              );
                            }}
                            onChange={handleSupplierOnChange}
                            isLoading={loadingRequest}
                            isClearable
                            backspaceRemovesValue
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                        <div className="w-100 pe-5">
                          <div className="w-100 d-flex">
                            <label className="form-label w-auto">
                              Nombre:{" "}
                            </label>
                          </div>
                          <div className="w-100 d-flex">
                            <span
                              className="form-control border-0 text-muted fs-6"
                              style={{ paddingLeft: "0px" }}
                            >
                              {supplierSelected?.label
                                ? supplierSelected.label
                                : "Seleccione el puesto"}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                        <div className="w-100 pe-5">
                          <div className="w-100 d-flex">
                            <label className="form-label w-auto">
                              Teléfono:{" "}
                            </label>
                          </div>
                          <div className="w-100 d-flex">
                            <span
                              className="form-control border-0 text-muted pl-0"
                              style={{ paddingLeft: "0px" }}
                            >
                              {supplierSelected?.label
                                ? "No registrado"
                                : "Seleccione el puesto"}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                        <div className="w-100 pe-5">
                          <button
                            type="button"
                            title="Buscar"
                            disabled={isDisabled}
                            onClick={() => {
                              setShowSaleForm(!showSaleForm);
                            }}
                            className={`btn w-200px ${
                              !showSaleForm ? "btn-primary" : "btn-light"
                            }`}
                          >
                            <span className="indicator-label">
                              {!showSaleForm ? "Buscar" : "Cancelar"}
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {!showSaleForm ? (
            <>
              <div className="card mb-6 mb-xl-9">
                <div className="card-header border-0">
                  <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bold text-gray-800">
                      Cerrar día administrativo
                    </span>
                  </h3>
                </div>
                <div className="card-body">
                  <div className="d-flex">
                    <button
                      type="button"
                      onClick={async () => {
                        try {
                          await closeDay(currentDay);
                          setReloadInfo(Math.random() * 50);
                        } catch (error) {
                          console.log(error);
                        }
                      }}
                      className="btn w-200px btn-danger ms-4"
                      data-kt-users-modal-action="submit"
                      disabled={loadingCloseDay || isDisabledBtnCloseDay}
                    >
                      {!loadingCloseDay && (
                        <span className="indicator-label">Cerrar día</span>
                      )}
                      {loadingCloseDay && (
                        <span
                          className="indicator-progress"
                          style={{ display: "block" }}
                        >
                          Espere por favor...
                          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      )}
                    </button>
                    <div className="d-flex justify-content-center flex-column ms-10">
                      <span className="fs-4 text-gray-700">{strDate}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className={`card card-xxl-stretch mb-5 mb-xl-12`}>
                <div className="d-flex flex-column flex-xl-row">
                  <div className="flex-lg-row-fluid">
                    <div className="card mb-6 mb-xl-9">
                      <div className="card-header border-0">
                        <h3 className="card-title align-items-start flex-column">
                          <span className="card-label fw-bold text-gray-800">
                            Conciliaciones realizadas
                          </span>
                        </h3>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                            <ConciliationGrid
                              reloadGrid={reloadInfo}
                              strDate={strDate}
                            ></ConciliationGrid>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : null}

          {showSaleForm ? (
            <div className="card">
              <div className="card-header pt-5">
                <h3 className="card-title align-items-start flex-column">
                  <span className="card-label fw-bold text-gray-800">
                    Ventas
                  </span>
                </h3>
                <h3 className="card-title">
                  <span className="card-label fw-bold text-gray-800">
                    Fecha administrativa:
                  </span>
                  <span className="fs-4 text-gray-700">{strDate}</span>
                </h3>
              </div>
              <div className="card-body">
                <div className="row w-100 ">
                  <div className="col-md-12 ">
                    <ConciliationForm
                      supplierId={supplierSelected?.value}
                      dayId={currentDay}
                      handleClose={closeSecction}
                    ></ConciliationForm>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export { View };
