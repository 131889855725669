



import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { SimpleAlert } from '../../../../../rbts/helpers';
import { Ticket } from '../models/EnlistmentModel';
import { createEnlistmentTicket, getTicketByFolio } from '../../../../service/enlistment';

export const useGetTicketByFolio = (filter: any) => {
  const [ticket, setTicket] = useState<Ticket | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();

  useEffect(() => {
    const fetchTicket = async () => {
      setLoading(true);
      try {
        const data = await getTicketByFolio(filter);
        if (data) {
          setTicket(data);
        }
      } catch (error: any) {
        let errorMessage = 'Ha ocurrido un error al obtener el ticket.';
        if (error.response && error.response.data && error.response.data.message) {
          errorMessage = error.response.data.message;
        }
        // console.error('Error al obtener el ticket:', error.message);
        SimpleAlert(errorMessage, 3000, 'error');
      } finally {
        setLoading(false);
      }
    };

    if (filter.ticketcash) {
      fetchTicket();
    }else{
      setTicket(null)
    }

  }, [filter]);

  return { ticket, setTicket, loading };
};

export const useEnlistmentTicket = () => {

  const [loading, setLoading] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [needConfirm, setNeedConfirm] = useState<boolean>(false);
  const [textNeedConfirm, setTextNeedConfirm] = useState<string>("");

  const history = useHistory();
  const enlistmentTicket = async (values: any) => {
    setLoading(true);
    setIsSuccess(false);
    setNeedConfirm(false);
    setTextNeedConfirm("");
    try {
      const response = await createEnlistmentTicket(values);
      if(response){
        SimpleAlert(response.data.message, 3000, 'success');
        setLoading(false);
        setIsSuccess(true);
      }
    } catch (error: any) {
      if (error && error.response) {
        const { status, data } = error.response;
        if (status === 422) {
          setTextNeedConfirm(data.message);
          setNeedConfirm(true);
          return;
        }
        SimpleAlert(data.message, 3000, 'error');
        setLoading(false);
      } else {
        history.push('/error/500');
      }
    }
  };
  return { enlistmentTicket, loading, setLoading, isSuccess, needConfirm, setNeedConfirm, textNeedConfirm  };
};