import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { getAllDaysSelect, getInfoCutPerDate } from "../../../../service/box_delivery";

export const useGetAllDaysSelect = (reload?: number) => {
  const [catalog, setCatalog] = useState<any>();
  const [loadingRequest, setLoadingRequest] = useState(true);

  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      const data = await getAllDaysSelect()
        .then()
        .catch((error: any) => {
          history.push("/error/500");
        });
      if (data) {
        setCatalog(data);
        setLoadingRequest(false);
      }
    };
    fetchPost();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  return { catalog, loadingRequest };
};


export const useGetInfoCutPerDate = (filter: any) => {
  const [detail, setDetail] = useState<any | null>();
  const [loadingRequest, setLoadingRequest] = useState(false);

  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      setLoadingRequest(true);
      const data = await getInfoCutPerDate(filter)
        .then()
        .catch((error: any) => {
          // history.push('/error/500');
        });
      if (data) {
        setDetail(data);
        setLoadingRequest(false);
      }
    };
    if(filter){
      fetchPost();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  return { detail, setDetail, loadingRequest };
};

