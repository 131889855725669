import axios from 'axios'
import { SelectFormat } from '../../../rbts/helpers/models/GenericModel'; 
const API_URL = process.env.REACT_APP_API;

export const getAllShiftsSelect = async () => {
    const response = await axios.get(`${API_URL}turno/`);
    const data = response.data.doc.data.rows;
    let select : SelectFormat[] = [];
    data.forEach((item: any) => {
        const selectCustom = {
          id: Number(item.id),
          value: item.id,
          label: `${item.nombre}`,
          data: item
        };
        select.push(selectCustom);
    });
    return select;
  
}
export const getAllPOSSelect = async () => {
  const response = await axios.post(`${API_URL}puntoVenta/tipo`,{
    tipo: "Caja"
  });
  const data = response.data.doc.data;
  let select : SelectFormat[] = [];
  data.forEach((item: any) => {
      const selectCustom = {
        id: Number(item.id),
        value: item.id,
        label: `${item.nombre}`,
        data: item
      };
      select.push(selectCustom);
  });
  return select;
}
export const getAllDaysSelect = async () => {
  const response = await axios.get(`${API_URL}diaventa/`);
  const data = response.data.doc.data;
  return data;
}
export const getDetailInit = async (id: number) => {
  try {
    const response = await axios.get(`${API_URL}transaccioncaja/info/init/caja/${id}`);
    return response.data.doc.data;
  } catch (err) {
    throw err;
  }
}
export const getDetailFinish = async (id: number) => {
  try {
    const response = await axios.get(`${API_URL}transaccioncaja/info/corte/cerrado/${id}`);
    return response.data.doc.data;
  } catch (err) {
    throw err;
  }
}
export const getInfoPerUser = async (id_usuario_encargado: number) => {
  try {
    const response = await axios.get(`${API_URL}transaccioncaja/corte/cajero/${id_usuario_encargado}`);
  const data = response.data.doc.data;
  return data;
  } catch (err) {
    console.error("Error al obtener información de corte:", err);
    throw err;
  }
}

export const getAllCashierUserSelect = async () => {
  const response = await axios.get(`${API_URL}users/role/users/CAJA`);
  const data = response.data.doc.data;
  let select : SelectFormat[] = [];
  data.forEach((item: any) => {
      const selectCustom = {
        id: Number(item.id),
        value: item.id,
        label: `${item.nombre_completo}`,
        data: item
      };
      select.push(selectCustom);
  });
  return select;
}

export const getAllProducts = async () => {
  try {
    const response = await axios.get(`${API_URL}producto/tipo/CAJA`);
    const data = response.data.doc.data;
    return data;
  } catch (err) {
    console.error("Error al obtener los productos:", err);
    throw err;
  }
}


export const getBoxesStarted = async (id_dia: number) => {
  try {
    const response = await axios.get(`${API_URL}transaccioncaja/cajas/iniciadas/${id_dia}`);
    const data = response.data.doc.data;
    return data;
  } catch (err) {
    console.error("Error al obtener las cajas iniciadas:", err);
    throw err;
  }
}

export const getCutStarted = async () => {
  try {
    const response = await axios.get(`${API_URL}transaccioncaja/cajas/cortes`);
    const data = response.data.doc.data;
    return data;
  } catch (err) {
    console.error("Error al obtener los cortes:", err);
    throw err;
  }
}

export const createBoxDelivery = async (box: any) => {

  try {
    const response = await axios.post(`${API_URL}transaccioncaja/`,box);
    return response;
  } catch (err) {
    console.error("Error:", err);
    throw err;
  }
}
export const createInitDayDelivery = async () => {

  try {
    const response = await axios.post(`${API_URL}diaventa/`);
    return response;
  } catch (err) {
    throw err;
  }
}

export const createFinishDayDelivery = async (id_dia: number) => {

  try {
    const response = await axios.put(`${API_URL}diaventa/cerrar/`);
    return response;
  } catch (err) {
    throw err;
  }
}

export const getInfoCut = async (values: any) => {
  try {
    const { id_transaccion_caja } = values;
    const response = await axios.get(`${API_URL}transaccioncaja/info/corte/${id_transaccion_caja}`);
    const data = response.data.doc.data;
    return data;
  } catch (err) {
    console.error("Error al obtener la información:", err);
    throw err;
  }
}

export const createBoxCut = async (box: any) => {

  try {
    const response = await axios.put(`${API_URL}transaccioncaja/corte`,box);
    return response;
  } catch (err) {
    console.error("Error:", err);
    throw err;
  }
}

export const getInfoCutPerDate = async (values: any) => {
  try {
    const { id } = values;
    const response = await axios.get(`${API_URL}transaccioncaja/info/corte/cerrado/${id}`);
    const data = response.data.doc.data;
    return data;
  } catch (err) {
    console.error("Error al obtener la información:", err);
    throw err;
  }
}