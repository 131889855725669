import React, { useState, useEffect } from "react";
import { SalesSupplierForm } from "./SalesSupplierForm";
import {
  useGetAllDaysSelect,
  useSelectSuppliers,
} from "../hooks/SalesSupplierHook";
import { styleSelect } from "../../../../../rbts/assets/ts/_utils";
import Select from "react-select";
import { SalesSupplierGrid } from "./SalesSupplierGrid";

type Props = {
  id?: number;
};

const ViewAE: React.FC<Props> = ({ id }) => {
  const { suppliers, loadingRequest } = useSelectSuppliers();
  const { catalog: day } = useGetAllDaysSelect();

  const [supplierSelected, setSupplierSelected] = useState<any | null>([]);
  const [showSaleForm, setShowSaleForm] = useState<boolean>(false);
  const [strDate, setStrDate] = useState<string>("");
  const [currentDay, setCurrentDay] = useState<number>(0);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [filter, setFilter] = useState<any>({
    id_dia: null,
    id_punto_venta: null,
  });

  useEffect(() => {
    if (day) {
      const {
        dia: { id },
        fecha_str,
      } = day;
      setStrDate(fecha_str);
      setCurrentDay(id);
    } else {
    }
  }, [day]);

  useEffect(() => {
    if (currentDay) {
      setIsDisabled(false);
      setFilter({
        id_dia: currentDay,
        id_punto_venta: supplierSelected ? supplierSelected?.value : null,
      });
    }
  }, [currentDay]);

  useEffect(() => {
    setFilter({
      id_dia: currentDay ? currentDay : null,
      id_punto_venta: supplierSelected ? supplierSelected?.value : null,
    });
  }, [supplierSelected]);

  const handleSupplierOnChange = (selectedOption: any) => {
    if (selectedOption) {
      setSupplierSelected(selectedOption);
    } else {
      setSupplierSelected(null);
    }
  };

  const closeSecction = () => {
    setShowSaleForm(false);
  };

  return (
    <>
      <div className="d-flex flex-column flex-xl-row">
        <div className="col-xl-12">
          <div className={`card card-xxl-stretch mb-5`}>
            <div className="d-flex flex-column flex-xl-row">
              <div className="flex-lg-row-fluid">
                <div className="card mb-6">
                  <div className="card-header border-0">
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label fw-bold text-gray-800">
                        Proveedor
                      </span>
                    </h3>
                  </div>
                  <div className="card-body">
                    {isDisabled && !currentDay ? (
                      <div className="alert alert-dismissible bg-light-primary d-flex flex-column flex-sm-row p-5 mb-10">
                        <div className="d-flex flex-column text-primary pe-0 pe-sm-10">
                          <span className="fs-6">
                            No es posible crear ventas si no existe un día
                            activo
                          </span>
                        </div>
                      </div>
                    ) : null}
                    <div className="row">
                      <div className="col-sm-12 col-md-4 col-lg-3 col-xl-3 col-xxl-3">
                        <div className="w-100 pe-5">
                          <div className="w-100 d-flex">
                            <label className="required form-label w-auto">
                              Nombre del puesto:{" "}
                            </label>
                          </div>
                          <Select
                            options={suppliers}
                            value={supplierSelected}
                            styles={styleSelect}
                            placeholder={"Seleccione"}
                            isSearchable={true}
                            noOptionsMessage={() => {
                              return (
                                <span>{"No se encontraron registros"}</span>
                              );
                            }}
                            onChange={handleSupplierOnChange}
                            isLoading={loadingRequest}
                            isClearable
                            backspaceRemovesValue
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                        <div className="w-100 pe-5">
                          <div className="w-100 d-flex">
                            <label className="form-label w-auto">
                              Nombre:{" "}
                            </label>
                          </div>
                          <div className="w-100 d-flex">
                            <span
                              className="form-control border-0 text-muted fs-6"
                              style={{ paddingLeft: "0px" }}
                            >
                              {supplierSelected?.label
                                ? supplierSelected.label
                                : "Seleccione el puesto"}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                        <div className="w-100 pe-5">
                          <div className="w-100 d-flex">
                            <label className="form-label w-auto">
                              Teléfono:{" "}
                            </label>
                          </div>
                          <div className="w-100 d-flex">
                            <span
                              className="form-control border-0 text-muted pl-0"
                              style={{ paddingLeft: "0px" }}
                            >
                              {supplierSelected?.label
                                ? "No registrado"
                                : "Seleccione el puesto"}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                        <div className="w-100 pe-5">
                          <button
                            type="button"
                            title="Nueva venta"
                            disabled={isDisabled}
                            onClick={() => {
                              setShowSaleForm(!showSaleForm);
                            }}
                            className={`btn w-200px ${
                              !showSaleForm ? "btn-primary" : "btn-light"
                            }`}
                          >
                            <span className="indicator-label">
                              {!showSaleForm ? "Nueva venta" : "Cancelar"}
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {!showSaleForm ? (
            <div className={`card card-xxl-stretch mb-5 mb-xl-12`}>
              <div className="d-flex flex-column flex-xl-row">
                <div className="flex-lg-row-fluid">
                  <div className="card mb-6 mb-xl-9">
                    <div className="card-header border-0">
                      <h3 className="card-title align-items-start flex-column">
                        <span className="card-label fw-bold text-gray-800">
                          Ventas a proveedor
                        </span>
                      </h3>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                          <SalesSupplierGrid
                            filter={filter}
                          ></SalesSupplierGrid>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          {showSaleForm ? (
            <div className="card">
              <div className="card-header pt-5">
                <h3 className="card-title align-items-start flex-column">
                  <span className="card-label fw-bold text-gray-800">
                    Nueva venta
                  </span>
                </h3>
                <h3 className="card-title">
                  <span className="card-label fw-bold text-gray-800">
                    Fecha administrativa:
                  </span>
                  <span className="fs-4 text-gray-700">{strDate}</span>
                </h3>
              </div>
              <div className="card-body">
                <div className="row w-100 ">
                  <div className="col-md-12 ">
                    <SalesSupplierForm
                      supplierId={supplierSelected?.value}
                      dayId={currentDay}
                      handleClose={closeSecction}
                    ></SalesSupplierForm>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export { ViewAE };
