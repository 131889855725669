import React, { FC } from 'react';
import { PageTitle, PageLink } from '../../../../rbts/layout/core';
import { View } from './components/View';

const widgetsBreadCrumbs: Array<PageLink> = [];

const EnlistmentPage: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={widgetsBreadCrumbs}>Enrolamiento</PageTitle>
      <View />
    </>
  );
};

export { EnlistmentPage };
