import axios from "axios";
import { SelectFormat } from "../../../rbts/helpers/models/GenericModel";
import { SaleSupplierModel } from "../../pages/sales/Suppliers/models/SalesSupplierModel";
const API_URL = process.env.REACT_APP_API;

export async function getAllProductsTypeSupplies() {
  const response = await axios.get(`${API_URL}producto/tipo/INSUMO`);
  const data = response.data.doc.data;
  let select: SelectFormat[] = [];
  data.forEach((item: any) => {
    const selectCustom = {
      id: Number(item.id),
      value: item.id,
      label: `${item.nombre}`,
      data: item,
    };
    select.push(selectCustom);
  });
  return select;
}
export async function getAllSuppliersTypeJob() {
  const filter = {
    tipo: "PUESTO",
  };
  const response = await axios.post(`${API_URL}puntoVenta/tipo`, filter);
  const data = response.data.doc.data;
  let select: SelectFormat[] = [];
  data.forEach((item: any) => {
    const selectCustom = {
      id: Number(item.id),
      value: item.id,
      label: `${item.nombre}`,
      data: item,
    };
    select.push(selectCustom);
  });

  return select;
}

export async function createNewSale(body: SaleSupplierModel) {
  const response = await axios.post(`${API_URL}ventaInsumos`, body);
  const data = response.data.doc.data;
  return data;
}

export async function getAllSalesSuppliers(filter: any) {
  const { id_dia, id_punto_venta } = filter;

  let url = `${API_URL}ventaInsumos`;

  if (id_dia) {
    url += `?id_dia=${id_dia}`;
  }

  if (id_punto_venta) {
    url += id_dia
      ? `&id_punto_venta=${id_punto_venta}`
      : `?id_punto_venta=${id_punto_venta}`;
  }

  const response = await axios.get(`${url}`);
  const data = response.data.doc.data;
  return data;
}

export async function getSaleSupplierById(id: number) {
  const response = await axios.get(`${API_URL}ventaInsumos/${id}`);
  const data = response.data.doc.data;
  return data;
}
